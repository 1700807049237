import VuetifyES from '../node_modules/vuetify/src/locale/es.ts';
import VuetifyEN from '../node_modules/vuetify/src/locale/en.ts';

import Vue from "vue";

import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const app_lang = localStorage.getItem("app_lang");

const VuetifyLANGS = {'es': VuetifyES, 'en':VuetifyEN};

function loadLocaleMessages() {
	const locales = require.context(
		"./locales",
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i
		);
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	messages[app_lang].$vuetify = VuetifyLANGS[app_lang];
	return messages;
}

String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
}

export default new VueI18n({
	locale: app_lang,
	fallbackLocale: "en",
	messages: loadLocaleMessages(),
});