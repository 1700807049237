<template>
  <v-timeline dense id="CoursesTimeLineComponent">
    <v-timeline-item class="pt-2 pr-3" :class="{'lesson-clickable':course.lessons_completed.length>=lesson_index,'lesson-selected':selected_lesson_id==lesson.id}" v-for="(lesson, lesson_index) in ordered_lessons" :key="lesson.id" :color="getLessonDotColor(course,lesson,lesson_index)" fill-dot small>
      <template v-slot:icon>
        <v-icon color="white" small v-if="lesson.completed_on">mdi-check</v-icon>
        <span style="color:white" v-else>{{lesson_index + 1}}</span>
      </template>
      <div @click="$emit('loadLesson',[lesson.id,lesson,getLessonDotColor(course,lesson,lesson_index)!=='grey'])">
        <div class="font-weight-normal">
          <strong>{{ lesson.name }}</strong>
        </div>
        <div class="lesson-description" :title="lesson.description">{{ lesson.description }}</div>
      </div>
    </v-timeline-item>
    <v-timeline-item class="pt-2" :class="{'lesson-clickable':course.lessons_completed.length===course.total_lessons,'lesson-selected':selected_lesson_id==-1}" :color="getFinalTestDotColor(course)" :icon="course.mark?'mdi-check':null" fill-dot>
      <div @click="$emit('loadLesson',[-1,null,getFinalTestDotColor(course)!=='grey'])">
        <div class="font-weight-normal">
          <strong>{{ $t('course.final_test.title') }}</strong>
        </div>
        <div class="lesson-description">
          <span v-if="!course.mark">
            {{ $t('course.final_test.description') }}
          </span>
          <v-progress-circular class="mt-1 ml-1" :rotate="-90" :size="50" :width="5" :value="course.mark" :color="course.mark<50?'error':'success'" v-else>
            <span class="text-caption font-weight-bold">{{course.mark}}%</span>
          </v-progress-circular>            
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>          
</template>

<script>
import * as moment from 'moment';
import axios from 'axios';
export default {
  name: 'CoursesTimeLineComponent',
  props: ['course','selected_lesson_id'],
  data: () => ({

  }),
  created(){
    this.init();
  },
  mounted(){
  },
  watch: { 
  },
  computed:{
    courses(){
      return this.$store.state.user.courses;
    },
    ordered_lessons(){
      var lessons = [];
      for(var id in this.course.lessons)
        lessons.push({ ...this.course.lessons[id], ...{id:id}});
      return Object.values(lessons).sort((a, b) => a.next_lesson_id - b.next_lesson_id);
    }
  },
  methods:{
    init(){

    },
    getLessonDotColor(course,lesson,index){
      if(lesson.completed_on)
        return 'success';
      else if(course.lessons_completed.length >= index)
        return 'primary';
      else
        return 'grey';
    },
    getFinalTestDotColor(course){
      if(course.mark)
        return 'success'
      else if(course.lessons_completed.length===course.total_lessons)
        return 'primary';
      else 
        return 'grey';
    },
  } 
};
</script>

<style type="text/css">

#CoursesTimeLineComponent .v-timeline-item{
  cursor: default;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-clickable{
  color:  #000000cc;
  cursor: pointer;
  transition: 300ms linear;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-clickable:hover{
  background: #393c4d9e;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-clickable *{
  cursor: pointer;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-selected{
  background: #393c4d!important;
  margin: 12px 0;
  cursor: default;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-selected *{
  cursor: default;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-selected, #CoursesTimeLineComponent .v-timeline-item.lesson-clickable:hover{
  border-radius: 10px;
  color: white;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-selected .v-timeline-item__inner-dot.primary,#CoursesTimeLineComponent .v-timeline-item.lesson-clickable:hover .v-timeline-item__inner-dot.primary{
  background: white!important;
}
#CoursesTimeLineComponent .v-timeline-item.lesson-selected .v-timeline-item__inner-dot.primary span,#CoursesTimeLineComponent .v-timeline-item.lesson-clickable:hover .v-timeline-item__inner-dot.primary span{
  color: #393c4d!important;
}
#CoursesTimeLineComponent .v-timeline-item .lesson-description{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>