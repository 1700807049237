<template>
  <div id="PayslipsComponent" style="height:100%">
    <div style="height:100%">
      <v-row justify="center" align="center" v-if="$store.state.vars.loading.payslips">
        <v-col cols="12" class="text-center" v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-divider inset class="my-0"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" style="height:100%" v-else-if="!payslips.length">
        <v-col cols="12" class="text-center">
          <div class="text-subtitle-1">{{$t('payslip.no_data')}}</div>
          <br>
          <v-icon x-large>mdi-file-document-outline</v-icon>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" v-else>
        <v-col cols="12" class="pl-12 text-h6 font-weight-light">{{$t('payslip.title')}}</v-col>
        <v-list two-line width="80vw" v-for="(payslip, index) in payslips" :key="payslip.id">
          <template>
            <v-subheader class="text-capitalize" v-if="index===0 || payslips[index-1].month !== payslip.month">
              {{new Date(payslip.month).toLocaleString($app_lang, { month: "long" })}} {{payslip.month.substr(0,4)}}
            </v-subheader>   
            <v-list-item :disabled="preview.loading===payslip.id" @click="previewFile(payslip)">
              <v-list-item-avatar>
                <v-progress-circular indeterminate color="primary" width="2" size="20" v-if="preview.loading===payslip.id"></v-progress-circular>
                <v-icon large v-else>mdi-file-pdf</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{payslip.name}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('payslip.created_on',{date:timeToLocal(payslip.created_at,true)})}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset class="my-0"></v-divider>
          </template>
        </v-list>
      </v-row>
    </div>

    <v-snackbar v-model="preview.downloaded" :timeout="5000" top color="primary">
      <h6 class="ma-auto text-center" style="width:100%">{{ $t('payslip.downloaded',{path:preview.path}) }}</h6>
    </v-snackbar>

    <v-snackbar v-model="preview.error_downloading" :timeout="5000" top color="primary">
      <!--h6 class="ma-auto text-center" style="width:100%">{{ $t('payslip.error_downloading') }}</h6-->
      <h6 class="ma-auto text-center" style="width:100%">{{ preview.error }}</h6>
    </v-snackbar>

  </div>
</template>

<script>
  import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
  import * as moment from 'moment';
  import axios from 'axios';
  export default {
    name: 'PayslipsComponent',
    data: () => ({
      preview: {
        payslip: null,
        loading: null,
        downloaded: false,
        path:null,
      },
    }),
    created(){
      this.init();
    },
    mounted(){
    },
    watch: { 
    },
    computed:{
      payslips(){
        return this.$store.state.user.payslips;
      }
    },
    methods:{
      init(){
      },
      timeToLocal(date,with_date=false){
        if(date){
          var m = moment.utc(date).local();
          if(with_date)
            return m.locale(this.$app_lang).format('D MMM HH:mm');
          else
            return m.format('HH:mm');
        }else{
          return this.$t('time_management.working').toLowerCase();
        }
      },
      previewFile(payslip){
        let vm = this;
        vm.preview.loading = payslip.id;
        axios({
          method: 'GET',
          url: '/api/app/employee/payslip',
          params: {
            payslip: payslip.code
          },
        }).then(function (response) {
          console.log(response.data.pdf_link)
          if(response.data.success){
            if(Capacitor.platform==='web'){
              window.location = response.data.pdf_link;
            }else{
              const { Filesystem } = Plugins;
              const { App } = Plugins;
              App.openUrl({url:response.data.pdf_link});    
              /*
              try {           
                var content = 'data:application/pdf;base64,'+response.data.pdf;
                var fileName = payslip.name+".pdf";
                var path = 'seifti/payslips';
                var filePath = path +'/' + fileName;
                Filesystem.mkdir({
                  path: path,
                  recursive: true,
                  directory: FilesystemDirectory.Documents
                });
                Filesystem.writeFile({
                  path: filePath,
                  data: content,
                  directory: FilesystemDirectory.Documents,
                }).then(function (){
                  vm.preview.path = 'Documents/'+filePath;
                  vm.preview.downloaded = true;                  
                });
              } catch(e) {
                vm.preview.error_downloading = true;
                console.log(JSON.stringify(e));
              }*/
            }
          }
        }, function (error) {
        }).then(function (){
          vm.preview.loading = null;
        });
      },
    }  
  }
</script>