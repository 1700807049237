
import app_lang from './pre_import.js';

import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify/lib';


import store from './store.js';

import VueRouter from 'vue-router';
import routes from './routes.js';

import VueAxios from 'vue-axios';
import axios from 'axios';

import i18n from './i18n.js';

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate/dist/vee-validate.full';

require('@/assets/app.css')


Vue.config.productionTip = false;

Vue.prototype.$app_lang = app_lang;

import('vee-validate/dist/locale/'+Vue.prototype.$app_lang).then(locale => {
	localize(Vue.prototype.$app_lang,locale.default);
});

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(Vuetify);
Vue.use(i18n);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const router = new VueRouter({ mode: 'history', routes: routes});

const vuetify = new Vuetify({	
	theme: {
		themes: {
			light: {
				primary: '#393c4d',
				secondary: '#5e94c3',
				accent: '#ffb901',
			},
		},
	},
	lang:{
		current: Vue.prototype.$app_lang,
		t: (key, ...params) => i18n.t(key, params)
	}
});

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

axios.defaults.withCredentials = true;
var enviro = window.location.host.split('.');
if(enviro.pop() === 'io')
	axios.defaults.baseURL = enviro.pop() === 'test' ? 'http://seifti.test.io' : 'https://sass.seifti.io'; 
else
	axios.defaults.baseURL = 'http://seifti.test';
axios.defaults.headers.common['app_lang'] = app_lang;

if(!localStorage.getItem("deviceid"))
	localStorage.setItem("deviceid",Math.floor(Math.random() * 999999999999999999999)+1);

var urlparams = new URLSearchParams(window.location.search);
var socialite = urlparams.get('socialite');
var tmp_bearer = socialite ?? localStorage.getItem("bearer");	
if (tmp_bearer !== null)
	store.dispatch('updateBearer',{bearer:tmp_bearer,refresh_data:true,is_socialite:socialite!==null,router});

router.beforeEach((to, from, next) => {
	/*if (to.name==='welcome' && localStorage.getItem("bearer")) 
		next({ name: 'courses' });
	//else if(to.name!=='welcome' && !localStorage.getItem("bearer")) //No need - the log in page will appear anyway and after loggin the client see the intended page
		//next({ name: 'welcome' });
		else*/
			next();  
	})

new Vue({
	vuetify,
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')