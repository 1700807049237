//DASHBOARD
//const Profile = () => import('./components/ProfileComponent.vue');
const Welcome = () => import('./components/WelcomeComponent.vue');
const Profile = () => import('./components/ProfileComponent.vue');
const TimeControl = () => import('./components/TimeControlComponent.vue');
const TimeManagement = () => import('./components/TimeManagementComponent.vue');
const Assessments = () => import('./components/AssessmentsComponent.vue');
const Payslips = () => import('./components/PayslipsComponent.vue');
const Documents = () => import('./components/DocumentsComponent.vue');
const Courses = () => import('./components/CoursesComponent.vue');

export default [
{
	path: '/',
	name: 'welcome',
	component: Welcome,
},
{
	path: '/app/profile',
	name: 'profile',
	component: Profile,
},
{
	path: '/app/time/control',
	name: 'time_control',
	component: TimeControl,
},
{
	path: '/app/time/management',
	name: 'time_management',
	component: TimeManagement,
},
{
	path: '/app/assessments',
	name: 'assessments',
	component: Assessments,
},
{
	path: '/app/payslips',
	name: 'payslips',
	component: Payslips,
},
{
	path: '/app/documents',
	name: 'documents',
	component: Documents,
},
{
	path: '/app/courses',
	name: 'courses',
	component: Courses,
},
{ 
	path: '*', 
	redirect: '/app/courses' 
}
];