<template>
  <div id="DocumentsComponent" style="height:100%">
    <div style="height:100%">
      <v-row justify="center" align="center" v-if="$store.state.vars.loading.documents">
        <v-col cols="12" class="text-center" v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-divider inset class="my-0"></v-divider>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" v-else>
        <v-col cols="12" class="pl-12 text-h6 font-weight-light">{{$t('document.title')}}</v-col>
        <v-col cols="12" class="text-center">
          <v-tabs grow v-model="tab">
            <v-tab>{{$t('document.tab.signed')}}</v-tab>
            <v-tab>{{$t('document.tab.generic')}}</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" class="text-center" v-if="!documents.length">
          <div class="text-subtitle-1 mt-12">{{$t('document.no_data')}}</div>
          <br>
          <v-icon x-large>mdi-file-document-outline</v-icon>
        </v-col>
        <v-col cols="12" v-else>
          <v-list two-line width="80vw" v-for="(doc, index) in documents" :key="doc.id">
            <template>
              <v-subheader class="text-capitalize" v-if="index===0 || documents[index-1].created_at.substr(0,7) !== doc.created_at.substr(0,7)">
                {{new Date(doc.created_at.substr(5,2)).toLocaleString($app_lang, { month: "long" })}} {{doc.created_at.substr(0,4)}}
              </v-subheader>   
              <v-list-item :disabled="preview.loading===doc.id" @click="previewFile(doc)">
                <v-list-item-avatar>
                  <v-progress-circular indeterminate color="primary" width="2" size="20" v-if="preview.loading===doc.id"></v-progress-circular>
                  <v-icon large v-else>mdi-file-pdf</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-if="['emplo','external_emplo_agreement'].includes(doc.type_code)">{{$t('document.emplo')}}</v-list-item-title>
                  <v-list-item-title v-else>{{doc.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{$t('document.created_on',{date:timeToLocal(doc.created_at,true)})}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset class="my-0"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="preview.downloaded" :timeout="5000" top color="primary">
      <h6 class="ma-auto text-center" style="width:100%">{{ $t('document.downloaded',{path:preview.path}) }}</h6>
    </v-snackbar>

    <v-snackbar v-model="preview.error_downloading" :timeout="5000" top color="primary">
      <h6 class="ma-auto text-center" style="width:100%">{{ preview.error }}</h6>
    </v-snackbar>

  </div>
</template>

<script>
  import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
  import * as moment from 'moment';
  import axios from 'axios';
  export default {
    name: 'DocumentsComponent',
    data: () => ({
      tab: null,
      preview: {
        document: null,
        loading: null,
        downloaded: false,
        path:null,
      },
    }),
    created(){
      this.init();
    },
    mounted(){
    },
    watch: { 
    },
    computed:{
      signed_documents(){
        return this.$store.state.user.documents;
      },
      other_documents(){
        return [];
      },
      documents(){
        if(!this.tab)
          return this.signed_documents;
        else
          return [];//this.other_documents;
      }
    },
    methods:{
      init(){
      },
      timeToLocal(date,with_date=false){
        if(date){
          var m = moment.utc(date).local();
          if(with_date)
            return m.locale(this.$app_lang).format('D MMM HH:mm');
          else
            return m.format('HH:mm');
        }else{
          return this.$t('time_management.working').toLowerCase();
        }
      },
      previewFile(doc){
        let vm = this;
        vm.preview.loading = doc.id;
        axios({
          method: 'GET',
          url: '/api/app/employee/document',
          params: {
            document: doc.id
          },
        }).then(function (response) {
          console.log(response.data)
          if(response.data.success){
            if(Capacitor.platform==='web'){
              window.location = response.data.pdf_link;
            }else{
              const { Filesystem } = Plugins;
              const { App } = Plugins;
              App.openUrl({url:response.data.pdf_link});    
            }
          }
        }, function (error) {
        }).then(function (){
          vm.preview.loading = null;
        });
      },
    }  
  }
</script>