<template>
  <v-app>
    <v-app-bar color="white" app v-if="$store.state.vars.has_bearer">
      <div class="d-flex align-center">     
        <v-img alt="Company logo" class="shrink mr-2" contain :src="$store.state.user.company.logo.url_m+'&tk='+$store.state.vars.has_bearer" transition="scale-transition" max-width="100" max-height="38" style="position: relative;top: -2px;" v-if="$store.state.user.company&&$store.state.user.company.logo"/>
      </div>
      <v-spacer></v-spacer>
      <!--div class="align-center hidden-xs-only" v-if="$store.state.user.company&&$store.state.user.company.logo">
        <v-img alt="Company logo" class="shrink mr-2" contain :src="$store.state.user.company.logo.url_m+'&tk='+$store.state.vars.has_bearer" transition="scale-transition" width="100" style="position: relative;top: -2px;"/>
      </div-->
      <div class="d-flex align-center">
        <v-select v-model="lang_id" hide-details :items="['en','es']" solo flat style="max-width:70px">
          <template v-slot:item="{ on, item }">
            <img class="ma-auto" :alt="item" :src="getImgUrl('flags/'+item+'.png')" width="26"/>
          </template>
          <template v-slot:selection="{ item, index }">
            <img class="ma-auto" :alt="item" :src="getImgUrl('flags/'+item+'.png')" width="26"/>
          </template>
        </v-select>
        <div class="hidden-xs-only">
          <div style="font-size:6px">Powered by</div>
          <div>
            <img alt="Seifti logo" src="@/assets/logos/logo.png" width="40" style="position: relative;top: -2px;"/>
          </div>
        </div>
      </div>
      <v-spacer class="hidden-sm-and-up"></v-spacer>
    </v-app-bar>

    <v-main>
      <div class="text-center" v-if="!$store.state.user.name && $store.state.vars.loading.user">
        <v-progress-circular class="mt-12" color="primary" indeterminate size="128"></v-progress-circular>
      </div>
      <DrawerComponent v-else-if="$store.state.user.name"></DrawerComponent>
      <WelcomeComponent v-else/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    n_calls_failed: 0,
    lang_id: null,
    drawer:{
      model: false,
    },
  }),
  created(){
    this.lang_id = this.$app_lang;
  },
  watch: {
    lang_id(){
      if(this.lang_id && this.lang_id !== this.$app_lang){
        localStorage.setItem('app_lang',this.lang_id);
        location.reload(true);
      }
    },
  },
  methods: {
    getImgUrl(path) {
      return require('./assets/'+path)
    }
  }
};
</script>

<style type="text/css">
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #666; 
}
pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f2334c;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
.v-application {
  /*Changing default font from Roboto to Nunito*/
  font-family: sans-serif!important;
}
.v-application a{
  color: #5e94c3!important;
}
.v-tab{
  /*font-weight: 600;*/
}
.v-text-field{
  padding: 12px 1px 0 1px;
}
.v-card__title{
  word-break:break-word;
}
:not(.v-text-field__slot) > textarea{
  border-radius: 4px;
  margin: 12px 8px;
  padding: 12px;
  border:1px solid rgba(0,0,0,.2);
}
.label{
  color: rgba(0,0,0,.54);
}
.label-accent label{
  color:'#ffb901';
}
.v-input--selection-controls label{
  margin-bottom: 0;
  word-break: break-word;
}
.v-input--switch label{
  padding-left: 14px;
}
.iec_radio .v-input__control{
  margin: auto;
}
.iec_radio .v-radio, .iec_radio.v-input--checkbox{
  overflow: hidden;
  border: 1px solid rgba(0,0,0,.24);
  border-radius: 12px;
  width: 126px;
  padding-left: 10px;
  margin: 0;
}

.iec_radio .v-radio label, .iec_radio.v-input--checkbox label{
  padding: 20px 4px 20px 4px;
  margin: 0;
}

.iec_radio .v-radio.theme--light:first-child{
  margin-right: 8px;
}

.iec_radio .v-radio.theme--light:last-child{
  margin-left: 8px;
}

.iec_radio .v-radio.theme--light.v-item--active, .iec_radio.v-input--checkbox.v-input--is-label-active{
  border-color: #393c4d;
}
.v-input--dense label, .v-input--dense input{
  font-size: 12px;
}
.capitalize_first_letter {
  display: inline-block;
  text-transform: unset !important;
}
.capitalize_first_letter:first-letter {
  text-transform: uppercase !important;
}
.noshadow:not(.v-input--is-focused) .v-input__slot{
  box-shadow: none !important;
}
.v-text-field.text-center input{
  text-align: center;
}
</style>
