var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"},attrs:{"id":"AssessmentsComponent"}},[_c('div',{staticStyle:{"height":"100%"}},[(_vm.$store.state.vars.loading.assessments)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):(!_vm.assessments.length)?_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('assessment.no_data')))]),_c('br'),_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-file-document-outline")])],1)],1):_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pl-12 text-h6 font-weight-light",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t('assessment.title')))]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('assessment.not_share_msg'))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.assessments,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeToLocal(item.created_at,true))+" ")]}},{key:"item.type_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('assessment.type_code.'+item.type_code))+" ")]}},{key:"item.percentage_answered",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{attrs:{"rotate":-90,"size":"35","width":2,"value":item.percentage_answered,"color":item.percentage_answered<34?'error':(item.percentage_answered<67?'warning':'success')}},[(item.percentage_answered<100)?_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.percentage_answered)+"% ")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-check")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"small":"","icon":"","color":"secondary","href":item.url,"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }