<template>
  <v-container id="CoursesComponent" style="height:100%">

    <v-row align="center" v-if="$store.state.vars.loading.courses">
      <v-col cols="12" sm="10" md="6" class="text-center" v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
        <v-skeleton-loader type="card" style="height:500px"></v-skeleton-loader>
        <v-divider inset class="my-0"></v-divider>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" style="height:100%" v-else-if="!courses || !(Object.keys(courses)).length">
      <v-col cols="12" class="text-center">
        <div class="text-subtitle-1">{{$t('course.no_data')}}</div>
        <br>
        <v-icon x-large>mdi-file-document-outline</v-icon>
      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="12" class="pl-12 text-h6 font-weight-light">{{$t('course.title')}}</v-col>
      <v-col cols="12" sm="10" md="6" v-for="(course, course_id, course_index) in courses" :key="course_id">
        <v-card>
          <v-img height="200px" :src="course.poster_url+'&tk='+$store.state.vars.has_bearer">
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="text-h6 primary--text white px-3 py-1" style="border-radius:8px">{{course.name}}</v-toolbar-title>
            </v-app-bar>
          </v-img>
          <v-card-title class="primary--text" style="align-items:end;justify-content:end">
            <v-avatar style="background:white;height:22px;min-width:initial;width:initial">
              <img alt="Seifti logo" src="@/assets/logos/icon.png" v-if="course.by_default" />
              <img alt="Company logo" :src="$store.state.user.company.logo.url_m+'&tk='+$store.state.vars.has_bearer" v-else-if="$store.state.user.company&&$store.state.user.company.logo" />
            </v-avatar>
            <p class="ml-2 text-caption mb-n1"><small>by</small> {{course.by_default ? 'Seifti' : $store.state.user.company.name}}</p>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              {{$t('course.lessons')}}
            </div>
            <CourseTimeLineComponent :course="course" @loadLesson="loadLesson(course_id,$event[0],$event[1],$event[2])"></CourseTimeLineComponent>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="lesson.dialog" fullscreen v-if="lesson.course_id">
      <v-card class="pb-4">
        <v-btn icon style="position:absolute;right:0;" @click="lesson.dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="pb-6">
          {{courses[lesson.course_id].name}}
        </v-card-title>
        <v-card-text>
          <v-container style="max-width:95%!important">
            <v-row>
              <v-col cols="12" md="5" lg="4">
                <CourseTimeLineComponent :course="courses[lesson.course_id]" :selected_lesson_id="lesson.id" @loadLesson="loadLesson(lesson.course_id,$event[0],$event[1],$event[2])"></CourseTimeLineComponent>
              </v-col>
              <v-col cols="12" md="7" lg="8" v-if="lesson.item">
                <v-container>
                  <v-row class="text-h5">
                    <v-col cols="12" class="text-center">
                      {{lesson.item.name}}
                    </v-col>
                  </v-row>
                </v-container>
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step :step="steps.step1" v-if="lesson.item.videos.length">
                      {{$t('course.lesson.video')}}
                    </v-stepper-step>
                    <v-divider v-if="lesson.item.videos.length && (!Array.isArray(lesson.item.summaries) || !Array.isArray(lesson.item.questions))"></v-divider>
                    <v-stepper-step :step="steps.step2" v-if="!Array.isArray(lesson.item.summaries)"> 
                      {{$t('course.lesson.summary')}}
                    </v-stepper-step>
                    <v-divider v-if="!Array.isArray(lesson.item.questions) && !Array.isArray(lesson.item.questions)"></v-divider>
                    <v-stepper-step :step="steps.step3" v-if="!Array.isArray(lesson.item.questions)">
                      {{$t('course.lesson.test')}}
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content :step="steps.step1" v-if="lesson.item.videos.length">
                      <video style="width:100%" :autoplay="video_index===0" class="my-5" controls v-for="(video_url, video_index) in lesson.item.videos" :key="video_index">
                        <source :src="video_url+'&tk='+$store.state.vars.has_bearer" type="video/mp4"/>
                        Your browser does not support MP4 videos
                      </video>
                    </v-stepper-content>
                    <v-stepper-content :step="steps.step2" v-if="!Array.isArray(lesson.item.summaries)">
                      <v-container>
                        <v-row justify="center">              
                          <v-col cols="12" style="max-width:650px">
                            <v-container>
                              <v-row>
                                <v-col cols="12" class="text-h6 font-weight-regular">
                                  {{$t('course.lesson.description')}}
                                </v-col>
                              </v-row>
                              <v-row class="pb-7 pl-3">
                                <v-col cols="12" v-html="carriageToBR(lesson.item.description)">
                                </v-col>
                              </v-row>
                              <v-divider class="mx-auto" style="width:50%"></v-divider>
                              <v-row class="pt-7">
                                <v-col cols="12" class="text-h6 font-weight-regular">
                                  {{$t('course.lesson.key_points')}}
                                </v-col>
                              </v-row>
                              <v-row class="pl-3">
                                <v-col cols="12" v-for="(summary, summary_id, summary_index) in lesson.item.summaries" :key="summary_id">
                                  <div class="mb-1 text-subtitle-1 text-decoration-underline">{{summary.name}}</div>
                                  <p v-html="carriageToBR(summary.description)"></p>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                    <v-stepper-content :step="steps.step3" v-if="!Array.isArray(lesson.item.questions)">
                      <v-container>
                        <v-row>
                          <v-col cols="12" style="max-width:650px" v-for="(question, question_id, question_index) in lesson.questions" :key="'q'+lesson.id+'_'+question_id">
                            <div class="text-subtitle-2 text-subtitle-lg-1">{{question.name}}</div>
                            <div class="font-italic" v-if="question.description">{{question.description}}</div>
                            <div class="mt-3">
                              <v-radio-group v-model="lesson.questions[question_id].answer_id" :disabled="lesson.validated || lesson.validating">
                                <v-radio :value="answer_id" color="primary" :on-icon="lesson.validated?(isLessonAnswerWrong(question_id,answer_id,question)?'mdi-close':'mdi-check'):'mdi-radiobox-marked'" active-class="selected-answer" class="answer text-caption" :class="{'wrong-answer':isLessonAnswerWrong(question_id,answer_id,question),'correct-answer':isLessonAnswerCorrect(question,answer_id)}" v-for="(answer, answer_id, answer_index) in question.answers" :key="answer_id">
                                  <template v-slot:label>
                                    <div class="text-body-2">
                                      {{answer.name}}
                                      <div class="text-caption font-italic" v-if="isLessonAnswerCorrect(question,answer_id)&&question.correct_answer_explanations[answer_id]">{{question.correct_answer_explanations[answer_id]}}</div>
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </div>
                          </v-col>
                          <v-col cols="12" class="text-center mt-3" style="max-width:750px">
                            <v-btn color="secondary" :disabled="!allLessonQuestionsAnswered || lesson.validated" :loading="lesson.validating" @click="validateLessonQuestions">{{$t('course.lesson.actions.validate')}}</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
                <v-container class="mt-10">
                  <v-row>
                    <v-col cols="12" class="text-right" v-if="step!==steps.step3 || lesson.validated">
                      <v-btn color="accent" class="mr-12" @click="step--" v-if="step>1">{{$t('course.lesson.actions.previous')}}</v-btn>
                      <v-btn color="primary" @click="nextStep" :loading="lesson.validating">{{$t('course.lesson.actions.next')}}</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" md="7" lg="8" v-else-if="lesson.id == -1">
                <v-container>
                  <v-row class="text-h5">
                    <v-col cols="12" class="text-center">
                      {{$t('course.final_test.title')}}
                    </v-col>
                  </v-row>
                  <v-row v-if="step<=final_test_n_questions.length">
                    <v-col cols="12" class="text-right">
                      <v-btn fab dark small color="primary" @click="openFeedbackDialog">
                        <v-icon dark>mdi-comment-quote</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <template v-for="final_q_step in final_test_n_questions">
                      <v-stepper-step :step="final_q_step" :key="final_q_step">
                      </v-stepper-step>
                      <v-divider :key="'d'+final_q_step"></v-divider>  
                    </template>
                    <v-stepper-step :step="final_test_n_questions.length+1">
                      {{$t('course.final_test.results.title')}}
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content :step="question_index+1" v-for="(question, question_id, question_index) in lesson.questions" :key="question_index+1">
                      <v-container>
                        <v-row>
                          <v-col cols="12" style="max-width:650px">
                            <div class="text-subtitle-2 text-subtitle-lg-1">{{question.name}}</div>
                            <div class="font-italic" v-if="question.description">{{question.description}}</div>
                            <div class="mt-3">
                              <v-radio-group v-model="lesson.questions[question_id].answer_id" :disabled="lesson.answering">
                                <v-radio :value="answer_id" color="primary" active-class="selected-answer" class="answer text-caption" :class="{'selected-answer':lesson.questions[question_id].answer_id==answer_id}" v-for="(answer, answer_id, answer_index) in question.answers" :key="answer_id" @click="nextStep(500)">
                                  <template v-slot:label>
                                    <div class="text-body-2">{{answer.name}}</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                    <v-stepper-content :step="final_test_n_questions.length+1">
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="text-center">
                            <v-progress-circular :rotate="-90" :size="200" :width="20" :value="courses[lesson.course_id].mark" :color="courses[lesson.course_id].mark<50?'error':'success'">
                              <span class="text-h6">{{ courses[lesson.course_id].mark }}%</span>
                            </v-progress-circular>
                          </v-col>
                        </v-row>
                        <v-divider class="my-12"></v-divider>
                        <v-row>
                          <v-col cols="12" style="max-width:650px" v-for="(question, question_id, question_index) in lesson.questions" :key="'q'+lesson.id+'_'+question_id">
                            <div class="text-subtitle-2 text-subtitle-lg-1">{{question.name}}</div>
                            <div class="font-italic" v-if="question.description">{{question.description}}</div>
                            <div class="mt-3">
                              <v-radio-group v-model="lesson.questions[question_id].answer_id" disabled>
                                <v-radio :value="answer_id" color="primary" :on-icon="lesson.validated?(isLessonAnswerWrong(question_id,answer_id,question)?'mdi-close':'mdi-check'):'mdi-radiobox-marked'" active-class="selected-answer" class="answer text-caption" :class="{'wrong-answer':isLessonAnswerWrong(question_id,answer_id,question),'correct-answer':isLessonAnswerCorrect(question,answer_id)}" v-for="(answer, answer_id, answer_index) in question.answers" :key="answer_id">
                                  <template v-slot:label>
                                    <div class="text-body-2">
                                      {{answer.name}}
                                      <div class="text-caption font-italic" v-if="isLessonAnswerCorrect(question,answer_id)&&question.correct_answer_explanations[answer_id]">{{question.correct_answer_explanations[answer_id]}}</div>
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </div>
                          </v-col>
                        </v-row>
                        <v-divider class="my-12"></v-divider>
                        <v-row>
                          <v-col cols="12" class="text-center">
                            <v-rating v-model="lesson.course_rate" color="warning" hover length="5" size="64" @input="rateCourse"></v-rating>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea v-model="lesson.comment.course_feedback" outlined auto-grow :label="$t('course.final_test.comment')" @blur="saveLessonComment(lesson.comment.course_feedback)"></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
                <v-container class="mt-10">
                  <v-row>
                    <v-col cols="12" class="text-center" v-if="step == final_test_n_questions.length && allLessonQuestionsAnswered">
                      <v-btn color="secondary" :loading="lesson.validating" :disabled="lesson.validated" @click="validateLessonQuestions">{{$t('course.lesson.actions.validate')}}</v-btn>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn color="accent" class="mr-12" @click="step--" v-if="step>1">{{$t('course.lesson.actions.previous')}}</v-btn>
                      <v-btn color="primary" @click="nextStep" v-if="step < final_test_n_questions.length && lesson.questions[Object.keys(lesson.questions)[step-1]].answer_id">{{$t('course.lesson.actions.next')}}</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lesson.comment.dialog" persistent max-width="500px">
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-card class="pb-4">
          <v-card-title class="pb-6">            
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12">
                  {{$t('course.lesson.comment.text')}}:
                </v-col>
              </v-row>
              <v-row class="pb-0">
                <v-col cols="12" class="pb-0">
                  <ValidationProvider vid="lesson_comment" name=" " rules="required" v-slot="{ errors, valid }">
                    <v-textarea v-model="lesson.comment.text" outlined auto-grow :error-messages="errors"></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn color="secondary" :disabled="invalid" :loading="lesson.comment.updating" @click="passes(saveLessonComment)">{{$t('generic.actions.save')}}</v-btn>
            <v-btn color="error" :disabled="lesson.comment.updating" outlined @click="lesson.comment.dialog=false">{{$t('generic.actions.close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <v-snackbar v-model="lesson.comment.snackbar" :timeout="3000" top color="primary">
      <h6 class="ma-auto text-center" style="width:100%">{{ $t('course.lesson.comment.snackbar') }}</h6>
    </v-snackbar>

  </v-container>
</template>

<script>
import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import * as moment from 'moment';
import axios from 'axios';
export default {
  name: 'CoursesComponent',
  data: () => ({
    step: 1,
    steps:{
      step1: 1,
      step2: 2,
      step3: 3,
      total: 3,
    },
    lesson:{
      dialog: false,
      course_id: null,
      item: null,
      id: null,
      questions: {},
      validated: false,
      validating: false,
      answering: false,
      comment: {
        dialog: false,
        updating: false,
        question_id: null,
        text: null,
        snackbar: false
      },
      course_rate: false
    },
  }),
  created(){
    this.init();
  },
  mounted(){
  },
  watch: { 
  },
  computed:{
    courses(){
      return this.$store.state.user.courses;
    },
    final_test_n_questions(){
      if(this.lesson.course_id)
        return [ ...Array(Object.keys(this.lesson.questions).length+1).keys() ].splice(1);
      else
        return 0;
    },
    allLessonQuestionsAnswered(){
      if(!Array.isArray(this.lesson.questions)){
        for (var i = Object.keys(this.lesson.questions).length - 1; i >= 0; i--) {
          var q_id = Object.keys(this.lesson.questions)[i];
          if(!this.lesson.questions[q_id].answer_id)
            return false;
        }
      }
      return true;
    },
  },
  methods:{
    init(){

    },
    getLessonDotColor(course,lesson,index){
      if(lesson.completed_on)
        return 'success';
      else if(course.lessons_completed.length >= index)
        return 'primary';
      else
        return 'grey';
    },
    getFinalTestDotColor(course){
      if(course.mark)
        return 'success'
      else if(course.lessons_completed.length===course.total_lessons)
        return 'primary';
      else 
        return 'grey';
    },
    loadLesson(course_id,lesson_id,lesson,is_active,step=1){
      if(!is_active)
        return false;      

      var course = this.courses[course_id.toString()];
      var course_completed = course.mark && lesson_id == -1;

      this.lesson.course_id = course_id.toString();
      this.lesson.id = null;
      this.lesson.item = null;    
      this.lesson.validated = course_completed;
      this.lesson.course_rate = course.rate;
      this.lesson.dialog = true;
      this.lesson.comment.course_feedback = course.feedback;

      this.lesson.questions = {};
      var questions = {};
      var max_questions_to_display = 0;
      if(lesson_id > -1){
        questions = lesson.questions;
        max_questions_to_display = lesson.max_questions_to_display;
      }else{
        max_questions_to_display = course.final_test_max_questions;
        for(var i=0;i<Object.keys(course.lessons).length;i++)
          questions = {...questions, ...course.lessons[Object.keys(course.lessons)[i]].questions};
      }
      var question_ids = Object.keys(questions).map(function(n){ return [Math.random(), n] })
      .sort().map(function(n){ return n[1] });
      for(var x=0; x<question_ids.length && Object.keys(this.lesson.questions).length<max_questions_to_display; x++){
        var q_id = question_ids[x];
        var q = JSON.parse(JSON.stringify(questions[q_id]));
        if(lesson_id > -1 && !q.force_final_test || q.force_final_test && (!course_completed && lesson_id == -1 || course_completed && q.employee_answers.answer_ids.length)){
          this.$set(this.lesson.questions,q_id,q);
          this.$set(this.lesson.questions[q_id], 'answer_id', course_completed ? q.employee_answers.answer_ids[0].toString() : null);
        }
      }

      var curr_step = 0;
      if(lesson_id > -1){
        this.steps.step1 = lesson.videos.length ? ++curr_step : -1;
        this.steps.step2 = !Array.isArray(lesson.summaries) ? ++curr_step : -1;
        this.steps.step3 = !Array.isArray(lesson.questions) ? ++curr_step : -1;
        this.steps.total = curr_step;
      }else{
        this.steps.total = Object.keys(this.lesson.questions).length+1;
      }
      this.step = course_completed ? this.steps.total : step;

      this.$nextTick(() => {
        this.lesson.id = lesson_id.toString();
        if(lesson_id > -1)     
          this.lesson.item = this.$set(this.lesson,'item',JSON.parse(JSON.stringify(lesson)));
      });

    },
    nextStep(delay=0){
      let vm = this;
      vm.lesson.answering = true;
      setTimeout(function() {
        if(vm.lesson.id == -1 && vm.final_test_n_questions.length > vm.step || vm.lesson.id != -1 && vm.step < vm.steps.total)
          vm.step++;
        else if(vm.lesson.validated){
          if(vm.lesson.item.next_lesson_id)
            vm.loadLesson(vm.lesson.course_id, vm.lesson.item.next_lesson_id, vm.courses[vm.lesson.course_id].lessons[vm.lesson.item.next_lesson_id],true);
          else
            vm.loadLesson(vm.lesson.course_id, -1, null, true);
        }else if(vm.lesson.item && Array.isArray(vm.lesson.item.questions)){
          vm.validateLessonQuestions(true);
        }
        vm.lesson.answering = false;
      }, delay);
    },
    validateLessonQuestions(auto_pass_next_lesson=false){
      let vm = this;
      vm.lesson.validating = true;
      var url = vm.lesson.id == -1 ? '/api/app/courses/validate' : '/api/app/courses/lesson/validate';
      let questions = [];
      if(!Array.isArray(vm.lesson.questions)){
        for (var i = Object.keys(vm.lesson.questions).length - 1; i >= 0; i--) {
          var q_id = Object.keys(vm.lesson.questions)[i];
          var q = vm.lesson.questions[q_id];
          questions.push({id:q_id,answer_id:q.answer_id});
        }
      }
      this.axios({
        method: 'POST',
        url: url,
        data:{
          course_id: vm.lesson.course_id,
          lesson_id: vm.lesson.id != -1 ? vm.lesson.id : null,
          question_ids: questions
        }
      }).then(function (response) {
        if(response.data.success){
          var course = null;
          var correct_answers = response.data.correct_answers;
          for(var i=0;i<correct_answers.length;i++){
            vm.lesson.questions[correct_answers[i].question_id].correct_answer_ids = correct_answers[i].correct_answer_ids;
            vm.lesson.questions[correct_answers[i].question_id].correct_answer_explanations = correct_answers[i].correct_answer_explanations;
          }
          if(vm.lesson.id == -1){
            course = response.data.course;
            vm.step++;
          }else{
            course = JSON.parse(JSON.stringify(vm.courses[vm.lesson.course_id]));
            if(!course.lessons[vm.lesson.id].completed_on)
              course.lessons_completed.push(parseInt(vm.lesson.id));
            course.lessons[vm.lesson.id].n_answered_questions = questions.length;
            course.lessons[vm.lesson.id].percentage_complete = 100;
            course.lessons[vm.lesson.id].completed_on = new Date().toISOString().slice(0, 19).replace('T', ' ');
          }
          vm.$store.commit('updateCourseData',{id:vm.lesson.course_id,course:course});
          vm.lesson.validated = true;
          if(auto_pass_next_lesson === true)
            vm.nextStep();
        }
      }, function (error) {
      }).then(function (){
        vm.lesson.validating = false;
      });
    },
    saveLessonComment(text_to_save=null){
      let vm = this;
      let text = text_to_save;
      if(!text_to_save)
        text = this.lesson.comment.text;
      vm.lesson.comment.updating = true;
      let question_id = this.step <= this.final_test_n_questions.length ? Object.keys(this.lesson.questions)[this.step-1] : null;
      var url = question_id ? '/api/app/courses/question/comment' : '/api/app/courses/comment';
      this.axios({
        method: 'POST',
        url: url,
        data:{
          course_id: vm.lesson.course_id,
          question_id: question_id,
          text: text
        }
      }).then(function (response) {
        if(response.data.success){
          var course = JSON.parse(JSON.stringify(vm.courses[vm.lesson.course_id]));
          if(question_id){
            var lesson_id = null
            for (var i = Object.keys(course.lessons).length - 1; i >= 0; i--) {
              lesson_id = Object.keys(course.lessons)[i];
              if(course.lessons[lesson_id].questions[question_id])
                break;
            }
            course.lessons[lesson_id].questions[question_id].feedback = text;
            vm.lesson.questions[question_id].feedback = text;
          }else{
            course.feedback = text;
          }
          vm.$store.commit('updateCourseData',{id:vm.lesson.course_id,course:course});
          vm.lesson.comment.snackbar = true;
          vm.lesson.comment.dialog = false;
        }
      }, function (error) {
      }).then(function (){
        vm.lesson.comment.updating = false;
      });
    },
    rateCourse(){
      let vm = this;
      this.axios({
        method: 'POST',
        url: '/api/app/courses/rate',
        data:{
          course_id: vm.lesson.course_id,
          rate: vm.lesson.course_rate
        }
      }).then(function (response) {
        if(response.data.success){
          var course = JSON.parse(JSON.stringify(vm.courses[vm.lesson.course_id]));
          course.rate = vm.lesson.course_rate;
          vm.$store.commit('updateCourseData',{id:vm.lesson.course_id,course:course});
        }
      }, function (error) {
      }).then(function (){
      });
    },
    isLessonAnswerWrong(question_id,answer_id,question){
      return this.lesson.questions[question_id].answer_id==answer_id&&this.lesson.validated&&!question.correct_answer_ids.includes(parseInt(answer_id));
    },
    isLessonAnswerCorrect(question,answer_id){
      return this.lesson.validated&&question.correct_answer_ids.includes(parseInt(answer_id))
    },
    openFeedbackDialog(text){
      this.lesson.comment.text = this.lesson.questions[Object.keys(this.lesson.questions)[this.step-1]].feedback;
      this.lesson.comment.dialog = true;
    },
    carriageToBR(text){
      return text.replace(/(\r\n|\n|\r)/g,'<br/>');
    }
  } 
};
</script>

<style type="text/css">
.answer label div.text-body-2{
  width:100%;
  border-radius: 6px;
  padding: 3px 8px;
  border: 3px solid white;
  padding: 3px 8px;
  transition:0.3s;
}
.answer:hover label div.text-body-2{
  color: white!important;
  background: #5e94c3ab;
  border-color: #5e94c3;
}
.selected-answer:not(.wrong-answer) label div{
  color: white!important;
  background: #393c4dc9;
  font-weight: bold;
  border-color: #393c4d;
}
.wrong-answer label div.text-body-2{
  color: red!important;
}
.correct-answer label div.text-body-2{
  color: white!important;
  background: #00800078;
  font-weight: bold;
  border-color: green;
}
</style>