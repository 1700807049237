var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-8",attrs:{"id":"ProfileComponent"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('v-container',{staticStyle:{"max-width":"100%"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-avatar',{attrs:{"size":"150"},on:{"click":function($event){return _vm.$refs.my_profile_pic.click()}}},[(_vm.picture.uploading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}}):_c('img',{attrs:{"src":_vm.$store.state.user.picture.url_t+(_vm.$store.state.user.picture.code?('&tk='+_vm.$store.state.vars.has_bearer):'')}})],1),_c('input',{ref:"my_profile_pic",staticClass:"d-none",attrs:{"type":"file","disabled":_vm.picture.uploading,"accept":"image/*","capture":""},on:{"change":_vm.imagePicked}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"user_name","name":" ","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"noshadow headline mx-auto text-center",staticStyle:{"max-width":"80%"},attrs:{"maxlength":"255","label":_vm.$t('generic.name_surnames'),"solo":"","error-messages":errors},on:{"change":function($event){return passes(_vm.updateData)}},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"vid":"user_nif","name":" ","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"maxlength":"20","label":"NIF","outlined":"","append-icon":"mdi-card-account-details-outline","error-messages":errors},on:{"change":function($event){return passes(_vm.updateData)}},model:{value:(_vm.user.nif),callback:function ($$v) {_vm.$set(_vm.user, "nif", $$v)},expression:"user.nif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"vid":"user_phone","name":" ","rules":"integer|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","maxlength":"20","label":_vm.$t('generic.phone'),"outlined":"","append-icon":"mdi-phone","error-messages":errors},on:{"change":function($event){return passes(_vm.updateData)}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","min-width":"300px","large":""},on:{"click":function($event){_vm.password.dialog=true}}},[_vm._v(_vm._s(_vm.$t('profile.reset_password')))])],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.password.dialog),callback:function ($$v) {_vm.$set(_vm.password, "dialog", $$v)},expression:"password.dialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('v-card',{staticClass:"pb-4"},[_c('v-btn',{staticStyle:{"position":"absolute","top":"8px","right":"8px"},attrs:{"text":""},on:{"click":function($event){_vm.password.dialog=false}}},[_vm._v("X")]),_c('v-card-title',{staticClass:"pb-6"}),_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',{staticClass:"pb-0"},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"password",attrs:{"vid":"password","name":" ","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","type":"password","label":_vm.$t('profile.password'),"error-messages":errors},model:{value:(_vm.password.password),callback:function ($$v) {_vm.$set(_vm.password, "password", $$v)},expression:"password.password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password_repeat","name":" ","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","type":"password","label":_vm.$t('profile.password_repeat'),"disabled":!_vm.password.password || _vm.password.password.length<8,"error-messages":errors},model:{value:(_vm.password.password_repeat),callback:function ($$v) {_vm.$set(_vm.password, "password_repeat", $$v)},expression:"password.password_repeat"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-4 pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":invalid,"loading":_vm.password.updating},on:{"click":function($event){return passes(_vm.updatePassword)}}},[_vm._v(_vm._s(_vm.$t('generic.actions.update')))]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.password.updating,"outlined":""},on:{"click":function($event){_vm.password.dialog=false}}},[_vm._v(_vm._s(_vm.$t('generic.actions.close')))])],1)],1)]}}])})],1),_c('v-snackbar',{attrs:{"timeout":3000,"top":"","color":"primary"},model:{value:(_vm.snackbars.data_updated),callback:function ($$v) {_vm.$set(_vm.snackbars, "data_updated", $$v)},expression:"snackbars.data_updated"}},[_c('h6',{staticClass:"ma-auto text-center",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.$t('profile.snackbar.updated')))])]),_c('v-snackbar',{attrs:{"timeout":5000,"top":"","color":"error"},model:{value:(_vm.snackbars.nif_used),callback:function ($$v) {_vm.$set(_vm.snackbars, "nif_used", $$v)},expression:"snackbars.nif_used"}},[_c('h6',{staticClass:"ma-auto text-center",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.$t('profile.snackbar.nif_used')))])]),_c('v-snackbar',{attrs:{"timeout":5000,"top":"","color":"error"},model:{value:(_vm.snackbars.phone_used),callback:function ($$v) {_vm.$set(_vm.snackbars, "phone_used", $$v)},expression:"snackbars.phone_used"}},[_c('h6',{staticClass:"ma-auto text-center",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.$t('profile.snackbar.phone_used')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }