import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import * as moment from 'moment';
import { Plugins, registerPlugin } from '@capacitor/core'
//import { BgLocationEvent, BgGeolocationAccuracy} from '@capacitor-community/background-geolocation';
//*****FOLLOW NEW VERSION INSTRUCTIONS HERE https://www.npmjs.com/package/@capacitor-community/background-geolocation*****/

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {},
		vars:{
			n_calls_failed: 0,
			has_bearer: false,
			geolocation: null,
			loading:{
				user: false,
				assessments: true,
				payslips: true,
				documents: true,
				courses: true,
				time_management: true,
				lastloc: null,
			}
		},
		messages:{
			no_rights: false
		}
	},
	mutations: {
		resetUserData (state,data) {
			state.user = {};
		},
		setFailedCalls (state,data){
			state.vars.n_calls_failed = data;
		},
		setUserData (state,data) {
			state.user = data;
		},
		setBasicUserData (state,data) {
			state.user.name = data.name;
			state.user.nif = data.nif;
			state.user.phone = data.phone;
		},
		setAssessmentsData(state,data){
			state.user.assessments = data;
		},
		setPayslipsData(state,data){
			state.user.payslips = data;
		},
		setDocumentsData(state,data){
			state.user.documents = data;
		},
		setFramesData(state,data){
			state.user.working_frames = data.frames;
			state.user.working_deleted_frames = data.deleted_frames;
			state.user.working_coordinates = data.coordinates;
		},
		setCoursesData(state,data){
			state.user.courses = data;
		},
		updateCourseData(state,data){
			state.user.courses[data.id] = data.course;
		},
		setContentLoading (state,data) {
			state.vars.loading[data.type] = data.loading;
		},
		setHasBearer(state,data){
			state.vars.has_bearer = data;
		},
		setGeolocation(state,data){
			state.vars.geolocation = data;
		},
		setLastloc(state,data){
			state.vars.lastloc = data;
		},
		setWorkingData(state,data){
			state.user.working = data.working;
			state.user.worked_today = data.worked_today;
			state.user.worked_today_updated_at = data.worked_today_updated_at;
		},
		updateProfilePicture(state,data){
			state.user.picture = data;
		},
		updateWorkingFrame(state,data){
			var date = data.date;
			var month = date.substr(0,7);
			if(data.frame_index !== null){
				state.user.working_frames[date][data.frame_index] = data.frame;
			}else{
				if(!state.user.working_frames[date]){
					Vue.set(state.user.working_frames, date, [data.frame]);
					Vue.set(state.user.working_seconds, date, 0);
					Vue.set(state.user.working_seconds_month, month, 0);
					if(!state.user.working_seconds_month[month])
						Vue.set(state.user.working_seconds_month, month, 0);
				}else{
					state.user.working_frames[date].push(data.frame);
				}
			}
			if(!data.frame.end_time)
				return false;
			console.log(state.user.working_seconds[date])
			state.user.working_seconds[date] += data.diff_seconds;
			state.user.working_seconds_month[month] += data.diff_seconds;
			console.log(state.user.working_seconds[date])
			var m = moment(data.date);
			var now = new Date();
			if(m.isSame(now, 'day')){
				state.user.worked_today += data.diff_seconds;
				state.user.worked_week += data.diff_seconds;
				state.user.worked_month += data.diff_seconds;
				state.user.worked_quarter += data.diff_seconds;
				state.user.worked_year += data.diff_seconds;
			}else if(m.isSame(now, 'week')){
				state.user.worked_week += data.diff_seconds;
				state.user.worked_month += data.diff_seconds;
				state.user.worked_quarter += data.diff_seconds;
				state.user.worked_year += data.diff_seconds;
			}else if(m.isSame(now, 'month')){
				state.user.worked_month += data.diff_seconds;
				state.user.worked_quarter += data.diff_seconds;
				state.user.worked_year += data.diff_seconds;
			}else if(m.quarter() === moment().quarter()){
				state.user.worked_quarter += data.diff_seconds;
				state.user.worked_year += data.diff_seconds;
			}
			else if(m.isSame(now, 'year'))
				state.user.worked_year += data.diff_seconds;
		},
		deleteWorkingFrame(state,data){
			if(state.user.working_deleted_frames[data.date])
				state.user.working_deleted_frames[data.date].push(data.frame);
			else
				state.user.working_deleted_frames[data.date] = [data.frame];
			state.user.working_frames[data.date].splice(data.frame_index,1);
			state.user.working_seconds[data.date] -= data.diff_seconds;
			state.user.working_seconds_month[data.date.substr(0,7)] -= data.diff_seconds;
			var m = moment(data.date);
			var now = new Date();
			if(m.isSame(now, 'day')){
				state.user.worked_today -= data.diff_seconds;
				state.user.worked_week -= data.diff_seconds;
				state.user.worked_month -= data.diff_seconds;
				state.user.worked_quarter -= data.diff_seconds;
				state.user.worked_year -= data.diff_seconds;
			}else if(m.isSame(now, 'week')){
				state.user.worked_week -= data.diff_seconds;
				state.user.worked_month -= data.diff_seconds;
				state.user.worked_quarter -= data.diff_seconds;
				state.user.worked_year -= data.diff_seconds;
			}else if(m.isSame(now, 'month')){
				state.user.worked_month -= data.diff_seconds;
				state.user.worked_quarter -= data.diff_seconds;
				state.user.worked_year -= data.diff_seconds;
			}else if(m.quarter() === moment().quarter()){
				state.user.worked_quarter -= data.diff_seconds;
				state.user.worked_year -= data.diff_seconds;
			}
			else if(m.isSame(now, 'year'))
				state.user.worked_year -= data.diff_seconds;
		}
	},
	actions: {
		updateBearer({ dispatch, commit, state },{ bearer, refresh_data, refresh_page, is_socialite, refresh_without_socialite}){
			if(bearer){
				axios.defaults.headers.common['Authorization'] = 'Bearer '+bearer;
				localStorage.setItem("bearer",bearer);
				commit('setHasBearer',bearer);
				if(is_socialite){
					dispatch('checkStatus',{is_socialite:is_socialite});
				}else if(refresh_without_socialite){
					var urlObject = new URL(window.location.href);
					urlObject.searchParams.delete('socialite');
					window.location.href = urlObject.href;
				}else if(refresh_page){
					location.reload(true);
				}
				if(refresh_data)
					dispatch('updateUserData',{is_refresh:false});
			}else{
				axios.defaults.headers.common['Authorization'] = null;
				localStorage.removeItem("bearer");
				commit('setHasBearer',false);
			}
		},
		checkStatus({ dispatch, commit, state },{is_socialite}){
			try{
				axios({
					method: 'GET',
					url: '/api/app/user/is_logged_in',
					params: { deviceid: localStorage.getItem("deviceid"), is_socialite: is_socialite ? 1 : 0 }
				}).then(function (response) {
					if(state.vars.n_calls_failed > 4){
						dispatch('updateBearer',{bearer:null});
						commit('setUserData',{});
					}else if(!response.data.is_logged_in){
						commit('setFailedCalls',state.vars.n_calls_failed+1);
						setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 1000);
					}else{
						commit('setFailedCalls',0);
						dispatch('updateBearer',{bearer:response.data.token,refresh_without_socialite:is_socialite});					
						setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 40*60*1000);
					}
				}, function (error) {
					commit('setFailedCalls',state.vars.n_calls_failed+1);
					setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 5000);
				}).then(function (){             
				});
			}catch(e){
				commit('setFailedCalls',state.vars.n_calls_failed+1);
				setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 5000);
			}
		},
		updateUserData ({ dispatch, commit, state },{is_refresh}) {
			commit('setContentLoading',{type:'user',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/user/employee'
			}).then(function (response) {
				var data = response.data;
				if(data.success){
					commit('setUserData',data.data);
					dispatch('updateAssessmentsData');
					dispatch('updatePayslipsData');
					dispatch('updateDocumentsData');
					dispatch('updateFramesData');
					dispatch('updateCoursesData');
					commit('setFailedCalls',0);
					if(!is_refresh)
						setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 5000);
				}else if(!is_refresh){
					commit('setUserData',{});
				}
			}).catch(function (error) {
				if(!is_refresh)
					setTimeout(function() {dispatch('checkStatus',{is_socialite:false})}, 500);
			}).finally(() => {
				commit('setContentLoading',{type:'user',loading:false});
			});
		},
		updatePayslipsData ({ dispatch, commit, state }) {
			commit('setContentLoading',{type:'payslips',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/employee/payslips',
			}).then(function (response) {
				if(response.data.success){
					commit('setPayslipsData',response.data.payslips);
				}
			}).catch(function (error) {
			}).finally(() => {
				commit('setContentLoading',{type:'payslips',loading:false});
			});
		},
		updateAssessmentsData ({ dispatch, commit, state }) {
			commit('setContentLoading',{type:'assessments',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/employee/assessments',
			}).then(function (response) {
				if(response.data.success){
					commit('setAssessmentsData',response.data.assessments);
				}
			}).catch(function (error) {
			}).finally(() => {
				commit('setContentLoading',{type:'assessments',loading:false});
			});
		},
		updateDocumentsData ({ dispatch, commit, state }) {
			commit('setContentLoading',{type:'documents',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/employee/documents',
			}).then(function (response) {
				console.log(response.data)
				if(response.data.success){
					commit('setDocumentsData',response.data.documents);
				}
			}).catch(function (error) {
			}).finally(() => {
				commit('setContentLoading',{type:'documents',loading:false});
			});
		},
		updateFramesData ({ dispatch, commit, state }) {
			commit('setContentLoading',{type:'time_management',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/employee/frames',
			}).then(function (response) {
				console.log(response.data)
				if(response.data.success){
					commit('setFramesData',response.data);
				}
			}).catch(function (error) {
			}).finally(() => {
				commit('setContentLoading',{type:'time_management',loading:false});
			});
		},
		updateCoursesData ({ dispatch, commit, state }) {
			commit('setContentLoading',{type:'courses',loading:true});
			axios({
				method: 'GET',
				url: '/api/app/employee/courses',
			}).then(function (response) {
				console.log(response.data)
				if(response.data.success){
					commit('setCoursesData',response.data.courses);
				}
			}).catch(function (error) {
			}).finally(() => {
				commit('setContentLoading',{type:'courses',loading:false});
			});
		},
		geolocate({ dispatch, commit, state },{loc}){
			var lastloc = state.vars.lastloc;
			if(lastloc && lastloc.longitude === loc.longitude && lastloc.latitude === loc.latitude)
				return false;
			console.log('saving location')
			commit('setLastloc',loc);
			axios({
				method: 'POST',
				url: '/api/app/employee/geolocate',
				data: {
					accuracy: loc.locationAccuracy ?? 0,
					lat: loc.latitude,
					long: loc.longitude,
				},
			}).then(function (response) {
			}, function (error) {					
			}).then(function (){   
			});
		},
		initGeoLocation({ dispatch, commit, state }){
			//*****FOLLOW NEW VERSION INSTRUCTIONS HERE https://www.npmjs.com/package/@capacitor-community/background-geolocation*****/
			/*var { BackgroundGeolocation }  = Plugins;
			commit('setGeolocation',BackgroundGeolocation);
			state.vars.geolocation.addListener('onLocation', (location) => {
				console.log('Got new location: '+location.latitude + ', '+location.longitude);
				dispatch('geolocate',{loc:location});
			});
			state.vars.geolocation.initialize({
				notificationText: 'Your app is running, tap to open.',
				notificationTitle: 'App Running',
				updateInteval: 10000,
				requestedAccuracy: BgGeolocationAccuracy.HIGH_ACCURACY,
				smallIcon: 'ic_small_icon',
				startImmediately: false,
			});
			console.log('geolocation initialized')*/
		},
		addBackgroundListener({ dispatch, commit, state }){
			//*****FOLLOW NEW VERSION INSTRUCTIONS HERE https://www.npmjs.com/package/@capacitor-community/background-geolocation*****/
			/*const { App, BackgroundTask } = Plugins;
			let user = state.user;
			//let geoobj = state.vars.geolocation;
			App.addListener('appStateChange', (state) => {
				if (!state.isActive) {
					let taskId = BackgroundTask.beforeExit(async () => {
						if(user.working)
							geoobj.start();
						console.log('start geolocation')
						//BackgroundTask.finish({
						//	taskId
						//});
					});
				}
			})*/
		},
		logout({ dispatch, commit, state }){
			axios({
				method: 'POST',
				url: '/api/app/logout',
				data: { deviceid: localStorage.getItem("deviceid") }
			}).then(function (response) {
			}, function (error) {					
			}).then(function (){
				commit('setUserData',{});
				dispatch('updateBearer',{bearer:null});
				setTimeout(function() {window.location.href=window.location.origin}, 200);  
			});
		},
	}
});