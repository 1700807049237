<template>
  <div id="TimeControlComponent" style="height:100%">
    <v-row justify="center" align="center" style="height:100%">
      <v-col cols="12">
        <div class="text-center text-subtitle-1">
          {{$t('time_control.worked_hours')}}
        </div>
        <div class="text-center text-h4 mt-1">
          {{format_seconds_worked}}
        </div>
        <div class="text-center mt-12">
          <v-btn elevation="8" :disabled="loadingUserData || $store.state.vars.loading.time_management" :loading="updating" fab width="150px" height="150px" :color="counting?'success':'error'" style="outline:none!important" @click="startFrame">
            <v-icon dark x-large v-if="counting">mdi-motion-pause-outline</v-icon>
            <v-icon dark x-large v-else>mdi-motion-play-outline</v-icon>
          </v-btn>
        </div>
        <div class="text-center mt-12">
          <v-icon class="animate-flicker" :color="counting?'default':'white'">mdi-crosshairs-gps</v-icon>          
        </div>
        <div class="text-center text-caption mt-12 mx-auto" style="max-width:80vw">
          <i>{{$t('time_control.note',{company:company.name})}}</i>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Plugins, Capacitor } from '@capacitor/core'
import * as moment from 'moment';
import 'moment-duration-format';
export default {
  name: 'TimeControlComponent',
  data: () => ({
    loc: null,
    counting: false,
    updating: false,
    locator: null,
    format_seconds_worked: '--:--:--',
    worked_today: 0,
    interval: null,
    frame: null,
    geolocation: null
  }),
  created(){
    var fsw = localStorage.getItem("TimeControlComponent__format_seconds_worked");
    if(fsw)
      this.format_seconds_worked = fsw;
    if(Capacitor.platform !== 'web'){
      const { Geolocation } = Plugins;
      this.geolocation = Geolocation;
      if(Capacitor.platform === 'android'){
        this.$store.dispatch('initGeoLocation');
        this.$store.dispatch('addBackgroundListener');
      }
    }
    this.init();
  },
  mounted(){
  },
  beforeDestroy(){
    localStorage.setItem("TimeControlComponent__format_seconds_worked",this.format_seconds_worked);
  },
  watch: { 
    counting(){  
      if(Capacitor.platform === 'android'){
        if(this.counting){
          this.$store.state.vars.geolocation.start();
        }else{
          this.$store.state.vars.geolocation.stop();
        }
      }
    },
    loadingUserData(){
      this.init();
    }
  },
  computed:{
    loadingUserData(){
      return this.$store.state.vars.loading.user;
    },
    company(){
      return this.$store.state.user.company;
    }
  },
  methods:{
    moment: function () {
      return moment();
    },
    init(){
      if(this.loadingUserData)
        return false;  
      this.counting = this.$store.state.user.working;
      if(this.counting)
        this.interval = setInterval(this.clockRunning, 1000);
      else
        this.clockRunning();
    },
    async getCurrentPosition() {
      let position = null;
      let coords = null;
      try {
        if(Capacitor.platform === 'web'){
          /*navigator.geolocation.getCurrentPosition(
            (pos) => {
              this.$store.dispatch('geolocate',{loc:pos.coords});
            }, 
            (err) => {
              console.log(err)
            }, 

            );
            */
            position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject, {enableHighAccuracy:true,timeout:5000,maximumAge:0});
            });
          }else{
            position = await this.geolocation.getCurrentPosition();
          }
        } catch(e) {
          console.log(e);
        }
        if(position){
          coords = position.coords;
          coords.locationAccuracy = Math.round(coords.accuracy);
          await this.$store.dispatch('geolocate',{loc:position.coords});
        }
        return coords;
      },
      clockRunning(){
        this.worked_today = this.$store.state.user.worked_today;
        if(this.counting)
          this.worked_today += Number(new Date())/1000 - this.$store.state.user.worked_today_updated_at;
        this.format_seconds_worked = moment.duration(this.worked_today,'seconds').format("hh:mm:ss", { trim: false });
      },
      async startFrame(){
        var coords = await this.getCurrentPosition();
        this.prStartFrame(coords);
      },
      prStartFrame(loc){
        let vm = this;
        vm.updating = true;
        this.axios({
          method: 'POST',
          url: vm.counting ? '/api/app/employee/job/stop' : '/api/app/employee/job/start',
          data:{
            user: vm.user,
            accuracy: loc ? (loc.locationAccuracy ?? 0) : null,
            lat: loc ? loc.latitude : null,
            long: loc ? loc.longitude : null,
          }
        }).then(function (response) {
          if(response.data.success){
            vm.counting = !vm.counting;
            vm.frame = response.data.frame
            var date = (new Date()).toISOString().substr(0,10);
            if(vm.counting){
              vm.$store.commit('setWorkingData',{working:true,worked_today:vm.worked_today,worked_today_updated_at:Number(new Date())/1000});
              vm.$store.commit('updateWorkingFrame',{date:date,frame_index:null,frame:vm.frame,diff_seconds:null});     
              vm.clockRunning();
              vm.interval = setInterval(vm.clockRunning, 1000);
            }else{
              var index = vm.$store.state.user.working_frames[date].findIndex(e=>e.id===vm.frame.id);
              vm.$store.commit('setWorkingData',{working:false,worked_today:vm.worked_today,worked_today_updated_at:Number(new Date())/1000});
              vm.$store.commit('updateWorkingFrame',{date:date,frame_index:index,frame:vm.frame,diff_seconds:vm.frame.seconds});   
              clearInterval(vm.interval);
            }
          }
        }, function (error) {
        }).then(function (){
          vm.updating = false;
        });
      }
    }  
  }
  </script>

  <style type="text/css">
  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
   -webkit-animation: flickerAnimation 5s infinite;
   -moz-animation: flickerAnimation 5s infinite;
   -o-animation: flickerAnimation 5s infinite;
   animation: flickerAnimation 5s infinite;
 }
 </style>