<template>
    <div id="ProfileComponent" class="py-8">
        <ValidationObserver v-slot="{ invalid, passes }">
            <v-container style="max-width:100%">
                <v-row justify="center">                                   
                    <v-avatar size="150" @click="$refs.my_profile_pic.click()">
                        <v-progress-circular indeterminate color="secondary" v-if="picture.uploading"></v-progress-circular>
                        <img :src="$store.state.user.picture.url_t+($store.state.user.picture.code?('&tk='+$store.state.vars.has_bearer):'')" v-else>
                    </v-avatar>
                    <input type="file" :disabled="picture.uploading" accept="image/*" capture ref="my_profile_pic" @change="imagePicked" class="d-none">
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <ValidationProvider vid="user_name" name=" " rules="required|max:255" v-slot="{ errors, valid }">
                            <v-text-field v-model="user.name" maxlength="255" class="noshadow headline mx-auto text-center" style="max-width:80%" :label="$t('generic.name_surnames')" solo :error-messages="errors" @change="passes(updateData)"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" sm="5">
                        <ValidationProvider vid="user_nif" name=" " rules="max:20" v-slot="{ errors, valid }">
                            <v-text-field v-model="user.nif" maxlength="20" label="NIF" outlined append-icon="mdi-card-account-details-outline" :error-messages="errors" @change="passes(updateData)"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="5">
                        <ValidationProvider vid="user_phone" name=" " rules="integer|max:20" v-slot="{ errors, valid }">
                            <v-text-field v-model="user.phone" type="number" maxlength="20" :label="$t('generic.phone')" outlined append-icon="mdi-phone" :error-messages="errors" @change="passes(updateData)"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-10">
                    <v-btn color="primary" min-width="300px" large @click="password.dialog=true">{{$t('profile.reset_password')}}</v-btn>
                </v-row>
            </v-container>
        </ValidationObserver>

        <v-dialog v-model="password.dialog" persistent max-width="500px">
            <ValidationObserver v-slot="{ invalid, passes }">
                <v-card class="pb-4">
                    <v-btn text style="position:absolute;top:8px;right:8px" @click="password.dialog=false">X</v-btn>
                    <v-card-title class="pb-6">
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <v-container class="pb-0">
                            <v-row class="pb-0">
                                <v-col cols="12" class="pb-0">
                                    <ValidationProvider vid="password" name=" " ref="password" rules="required|min:8" v-slot="{ errors, valid }">
                                        <v-text-field v-model="password.password" outlined type="password" :label="$t('profile.password')" :error-messages="errors"></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <ValidationProvider vid="password_repeat" name=" " rules="required|confirmed:password" v-slot="{ errors, valid }">
                                        <v-text-field v-model="password.password_repeat" outlined type="password" :label="$t('profile.password_repeat')" :disabled="!password.password || password.password.length<8" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="px-4 pb-4">
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" :disabled="invalid" :loading="password.updating" @click="passes(updatePassword)">{{$t('generic.actions.update')}}</v-btn>
                            <v-btn color="error" :disabled="password.updating" outlined @click="password.dialog=false">{{$t('generic.actions.close')}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-dialog>

            <v-snackbar v-model="snackbars.data_updated" :timeout="3000" top color="primary">
                <h6 class="ma-auto text-center" style="width:100%">{{ $t('profile.snackbar.updated') }}</h6>
            </v-snackbar>

            <v-snackbar v-model="snackbars.nif_used" :timeout="5000" top color="error">
                <h6 class="ma-auto text-center" style="width:100%">{{ $t('profile.snackbar.nif_used') }}</h6>
            </v-snackbar>

            <v-snackbar v-model="snackbars.phone_used" :timeout="5000" top color="error">
                <h6 class="ma-auto text-center" style="width:100%">{{ $t('profile.snackbar.phone_used') }}</h6>
            </v-snackbar>

        </div>
    </template>

    <script>
    export default {
        data () {
            return {
                user: null,
                picture:{
                    uploading: false,
                },
                password:{
                    dialog: false,
                    password: null,
                    password_repeat: null,
                    updating: false
                },
                snackbars:{
                    nif_used:false,
                    phone_used:false,
                    data_updated: false,
                }
            }
        },
        created(){
            this.user = Object.assign({}, this.$store.state.user);
        },
        mounted(){

        },
        methods:{
            updatePassword(){
                let vm = this;
                vm.password.updating = true;
                this.axios({
                    method: 'PATCH',
                    url: '/api/app/user/employee/password',
                    data:{
                        password: vm.password.password,
                        password_confirmation: vm.password.password_repeat
                    }
                }).then(function (response) {
                    if(response.data.success){
                        vm.password.dialog = false;
                        vm.password.password = null;
                        vm.password.password_repeat = null;
                        vm.snackbars.data_updated = true;
                    }
                }, function (error) {

                }).then(function (){
                    vm.password.updating = false;
                });
            },
            updateData(){
                let vm = this;
                this.axios({
                    method: 'PATCH',
                    url: '/api/app/user/employee',
                    data:{
                        user: vm.user
                    }
                }).then(function (response) {
                    if(response.data.success){
                        vm.user = response.data.user;
                        vm.$store.commit('setBasicUserData',response.data.user);
                        vm.snackbars.data_updated = true;
                    }else if(response.data.why_code === 'nif_used'){
                        vm.snackbars.nif_used = true;
                        vm.user.nif = vm.$store.state.user.nif;
                    }
                }, function (error) {

                }).then(function (){

                });
            },
            imagePicked(e){
                let vm = this;
                let files = e.target.files;
                let data = null;
                if(files.length === 0)
                    return false;
                data = new FormData();
                data.append('image_file', files[0], files[0].name);
                vm.picture.uploading = true;
                this.axios({
                    method: 'POST',
                    url: '/api/image',
                    data: data,
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(function (response) {
                    if(response.data.success){
                        vm.user.picture = response.data
                        vm.$store.commit('updateProfilePicture',response.data);
                        vm.updateData();
                    }
                }, function (error) {
                    e.srcElement.value = null;
                }).then(function (){
                    vm.picture.uploading = false;
                });
            },
        }    
    };

    </script>

    <style type="text/css">


    </style>