<template>
  <div id="WelcomeComponent" class="mx-auto" style="max-width:500px">
    <v-container>
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-row class="mb-12" justify="center">
          <img src="@/assets/logos/logo.png" width="220" height="76" />
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4" justify="center" align="center">
          <v-col cols="12" class="pb-0 text-subtitle-1 text-center">
            {{$t('welcome.main_text')}}:
            <v-select v-model="lang_id" hide-details :items="['en','es']" solo flat style="max-width:70px;display:inline-block;vertical-align:middle;">
              <template v-slot:item="{ on, item }">
                <img class="ma-auto" :alt="item" :src="getImgUrl('flags/'+item+'.png')" width="26"/>
              </template>
              <template v-slot:selection="{ item, index }">
                <img class="ma-auto" :alt="item" :src="getImgUrl('flags/'+item+'.png')" width="26"/>
              </template>
            </v-select>
          </v-col>
          <!--v-col cols="12" class="pt-0 text-center">
            <v-icon color="primary" style="font-size:45px">mdi-av-timer</v-icon>
            <v-icon color="primary" class="mx-3 my-1" style="font-size:45px">mdi-receipt</v-icon>
            <v-icon color="primary" style="font-size:45px">mdi-school</v-icon>
          </v-col-->
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <ValidationProvider vid="employee_email" name=" " rules="required|email|max:255" v-slot="{ errors, valid }">
              <v-text-field v-model="email" hide-details outlined append-icon="mdi-email" label="Email" :error-messages="errors" @keyup.enter="passes(logInEmployee)"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="10">
            <ValidationProvider vid="employee_password" name=" " rules="required|max:255" v-slot="{ errors, valid }">
              <v-text-field v-model="password" type="password" hide-details outlined append-icon="mdi-form-textbox-password" :label="$t('generic.password')" :error-messages="errors" @keyup.enter="passes(logInEmployee)"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="11" class="pa-0" v-if="login_incorrect">
            <p class="error--text text-center mb-0">{{$t('welcome.login_incorrect')}}</p>
          </v-col>
          <v-col cols="11">
            <p class="text-center primary--text text-body-2" v-html="$t('welcome.loggin_terms',{legal: axios.defaults.baseURL+'/site/policy/legal', privacy: axios.defaults.baseURL+'/site/policy/priva', cookies: axios.defaults.baseURL+'/site/policy/cooki'})"></p>
          </v-col>
          <v-col cols="10">
            <v-btn color="primary" large block :disabled="invalid" :loading="logging" @click="passes(logInEmployee)">{{$t('generic.actions.loggin')}}</v-btn>
          </v-col>
          <v-col cols="11" class="text-center">
            <a :href="axios.defaults.baseURL+'/password/reset'">{{$t('generic.forgot_password')}}</a>
          </v-col>
        </v-row>
        <v-divider class="my-8"></v-divider>
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <a :href="axios.defaults.baseURL+'/auth/redirect/google'">
              <div class="google-btn mx-auto">
                <div class="google-icon-wrapper">
                  <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                </div>
                <p class="btn-text mx-2"><b>Log in with Google</b></p>
              </div>
            </a>
          </v-col>
          <v-col cols="12" class="text-center">
            <a :href="axios.defaults.baseURL+'/auth/redirect/microsoft'">
              <div class="microsoft-btn mx-auto">
                <div class="microsoft-icon-wrapper">
                  <img class="microsoft-icon" src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"/>
                </div>
                <p class="btn-text"><b>Log in with Microsoft</b></p>
              </div>
            </a>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'WelcomeComponent',
  data: () => ({
    lang_id: 'en',
    email: null,
    password: null,
    login_incorrect: false,
    logging: false
  }),
  created(){
    this.lang_id = this.$app_lang;
  },
  watch: {
    lang_id(){
      if(this.lang_id && this.lang_id !== this.$app_lang){
        localStorage.setItem('app_lang',this.lang_id);
        location.reload(true);
      }
    },
  },
  methods:{
    getImgUrl(path) {
      return require('../assets/'+path);
    },
    logInEmployee(){
      let vm = this;
      vm.login_incorrect = false;
        //vm.axios.get('/sanctum/csrf-cookie').then(response => {
          vm.logging = true;
          vm.axios({
            method: 'POST',
            url: '/api/app/login/employee',
            data:{
              email: vm.email,
              password: vm.password,
              deviceid: localStorage.getItem("deviceid"),
            },
          }).then(function (response) {
            if(response.data.success){
              vm.$store.dispatch('updateBearer',{bearer:response.data.token,refresh_page:true,refresh_data:true});              
              if(vm.$route.name === 'welcome')
                vm.$router.push('home');
            }else if(response.data.why_code === 'not_valid'){
              vm.login_incorrect = true;
            }
          }, function (error) {
            console.log(error.message)
          }).then(function (){
            vm.logging = false;          
          });
        //});
      },
    }  
  };
  </script>

  <style type="text/css">
  #WelcomeComponent{
    min-height: 842px;
    height: 100vh;
    position: relative;
  }

  #WelcomeComponent > .container{
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .google-btn {
    width: 184px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  }
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
  }
  .google-icon {
    margin-top: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .google-btn:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  .google-btn:active {
    background: #1669F2;
  }
  .microsoft-btn {
    width: 200px;
    height: 42px;
    background-color: white;
    border: 1px solid #8c8c8c;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  }
  .microsoft-icon-wrapper {
    position: absolute;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
  }
  .microsoft-icon {
    margin-top: 11px;
    width: 18px;
    height: 18px;
  }
  .microsoft-btn:hover {
    box-shadow: 0 0 6px #2f2f2f;
  }
  .microsoft-btn:active {
    background: #2f2f2f;
    color: white;
  }
  .microsoft-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #2f2f2f;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  </style>