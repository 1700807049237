<template>
  <div id="TimeManagementComponent" style="height:100%" class="py-4">
    <v-row justify="center" align="center" v-if="$store.state.vars.loading.time_management">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="3">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
          <v-col cols="3">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-col>
      <v-row justify="center">
        <v-col cols="8">
          <v-skeleton-loader type="date-picker-days"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-row>
    <div style="height:100%" v-else-if="!allowed_dates">
      <v-row justify="center" align="center" style="height:100%">
        <v-col cols="12" class="text-center">
          <div class="text-subtitle-1">{{$t('time_management.no_data')}}</div>
          <br>
          <v-icon x-large>mdi-file-document-outline</v-icon>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <table>
            <tr>
              <th class="text-sm-subtitle-1">{{new Date().getFullYear()}}</th>
              <th class="text-sm-subtitle-1">{{$t('time_management.current_quarter',{q:moment().quarter()})}}</th>
              <th class="text-sm-subtitle-1">{{new Date().toLocaleString($app_lang, { month: "long" })}}</th>
              <th class="text-sm-subtitle-1">{{$t('time_management.current_week')}}</th>
            </tr>
            <tr>
              <td class="text-sm-h5">{{duration($store.state.user.worked_year,true)}}</td>
              <td class="text-sm-h5">{{duration($store.state.user.worked_quarter,true)}}</td>
              <td class="text-sm-h5">{{duration($store.state.user.worked_month,true)}}</td>
              <td class="text-sm-h5">{{duration($store.state.user.worked_week)}}</td>
            </tr>
          </table>
          <v-divider class="my-6"></v-divider>
          <v-date-picker v-model="date" :allowed-dates="allowedDates" class="mt-4" elevation="15" no-title
          :max="moment().format('YYYY-MM-DD')" first-day-of-week="1" color="primary"></v-date-picker>
          <div class="mt-10 text-subtitle-1" v-if="date">
            {{$t('time_management.working_on_month',{hours:duration($store.state.user.working_seconds_month[moment(date).format("YYYY-MM")],true),month:new Date(date).toLocaleString($app_lang, { month: "long" })})}}
          </div>
          <v-divider class="mt-8 mb-0"></v-divider>
          <v-tabs v-model="tab" fixed-tabs centered :show-arrows="false" class="mb-5" v-if="date">
            <v-tab @click="scrollTo('tmc1')">{{$t('time_management.title')}}</v-tab>
            <v-tab @click="scrollTo('tmc2')">{{$t('time_management.logs.title')}}</v-tab>
            <v-tab @click="scrollTo('tmc3')">{{$t('time_management.map.title')}}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" v-if="date">
            <v-tab-item :eager="true">
              <div id="tmc1" class="mt-5 text-subtitle-1">
                <h5>{{moment(date).locale($app_lang).format('LL')}}</h5>
                {{$t('time_management.working_on_day',{hours:duration($store.state.user.working_seconds[date])})}}
                <br>
                {{$t('time_management.resting_on_day',{hours:duration(restingOnDate(date))})}}
              </div>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-container id="tmc2">
                <v-row justify="center" class="mx-auto" style="max-width:80vw" v-if="!working_frames_on_date || !working_frames_on_date.length">
                  <span>{{$t('time_management.logs.empty')}}</span>
                </v-row>
                <v-row justify="center" align="center" v-for="(frame,frame_index) in working_frames_on_date" :key="frame.id" v-else>
                  <v-col cols="4">
                    <v-text-field :value="timeToLocal(frame.start_time)" :disabled="!frame.end_time || frame.approved !== null" :label="$t('generic.start')" prepend-icon="mdi-clock-time-four-outline" readonly color="secondary" @click="displayFrameDialog('edit',frame_index)"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field :value="timeToLocal(frame.end_time)" :disabled="frame.approved !== null" :label="$t('generic.end')" prepend-icon="mdi-clock-time-four-outline" readonly color="secondary" persistent-hint :hint="frame.start_time.substr(-8) > frame.end_time.substr(-8) ? $t('time_management.next_day') : ''" @click="displayFrameDialog('edit',frame_index)" v-if="frame.end_time"></v-text-field>
                    <v-text-field :value="$t('time_management.working')" disabled prepend-icon="mdi-clock-time-four-outline" v-else></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="2" md="1" class="text-start">
                    <v-menu bottom right offset-y transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="frame_logs.frames=[frame];frame_logs.dialog=true;">
                          <v-list-item-content>
                            {{$t('time_management.actions.check_log')}}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="displayFrameDialog('edit',frame_index)" v-if="frame.approved && frame.end_time">
                          <v-list-item-content>
                            {{$t('time_management.actions.edit_approved')}}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item :disabled="!frame.start_time || !frame.end_time" @click="displayFrameDialog('delete',frame_index)">
                          <v-list-item-content class="red--text">
                            {{$t('generic.actions.delete')}}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>                   
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9" class="text-right pr-0" v-if="date==today || $store.state.user.working_frames[date] && $store.state.user.working_frames[date].some(i => i.approved === null)">
                    <v-btn color="secondary" text @click="displayFrameDialog('add',null)">
                      <v-icon small class="mr-1">mdi-plus</v-icon>
                      {{$t('time_management.actions.new')}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="$store.state.user.working_deleted_frames[date]">
                  <v-col cols="12">
                    <v-btn color="error" small text @click="frame_logs.frames=$store.state.user.working_deleted_frames[date];frame_logs.dialog=true;">
                      <v-icon x-small class="mr-1">mdi-eye</v-icon>
                      {{$t('time_management.actions.display_deleted')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :eager="true">
              <p id="tmc3" class="pb-2 px-7 text-center text-caption">{{$t('time_management.map.description')}}</p>
              <p class="pb-2 px-7 text-center" v-if="!$store.state.user.working_coordinates[date]">
                {{$t('time_management.map.empty')}}
              </p>
              <div id="map" class="gmap" v-else></div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="frame_update.dialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="500px">
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-card class="pb-4" :min-height="$vuetify.breakpoint.xsOnly?'100vh':'auto'">
          <v-btn icon style="position:absolute;right:0;" @click="frame_update.dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-title class="py-6">
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="frame_update.frame_index !== null && $store.state.user.working_frames[frame_update.date][frame_update.frame_index] && $store.state.user.working_frames[frame_update.date][frame_update.frame_index].approved">
                <v-col cols="12" class="pt-0">
                  <v-alert dense text type="warning">
                    {{$t('time_management.alter_approved_frame')}}         
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="frame_update.action !== 'delete'">
                <v-col cols="6">                  
                  <v-menu ref="time_menu_start" v-model="frame_update.start_time.menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="frame_update.start_time.val" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider vid="start_time" name=" " rules="required" v-slot="{ errors, valid }">
                        <v-text-field v-model="frame_update.start_time.val" :label="$t('generic.start')" prepend-icon="mdi-clock-time-four-outline" readonly color="secondary" v-bind="attrs" v-on="on"></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-time-picker format="24hr" v-model="frame_update.start_time.val" color="primary" header-color="secondary" :allowed-minutes="m => m % 5 === 0" full-width @click:minute="$refs.time_menu_start.save(frame_update.start_time.val);" v-if="frame_update.start_time.menu" ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">                  
                  <v-menu ref="time_menu_end" v-model="frame_update.end_time.menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="frame_update.end_time.val" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider vid="end_time" name=" " rules="required" v-slot="{ errors, valid }">
                        <v-text-field v-model="frame_update.end_time.val" ref="time_field_end" :label="$t('generic.start')" prepend-icon="mdi-clock-time-four-outline" readonly color="secondary" persistent-hint :hint="isEndTimeNextDay ? $t('time_management.next_day') : ''" v-bind="attrs" v-on="on"></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-time-picker format="24hr" v-model="frame_update.end_time.val" color="primary" header-color="secondary" :allowed-minutes="m => m % 5 === 0" full-width @click:minute="$refs.time_menu_end.save(frame_update.end_time.val)" v-if="frame_update.end_time.menu"></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>  
              <v-row>
                <v-col cols="12">
                  <ValidationProvider vid="reason" name=" " rules="required|min:10|max:1000" v-slot="{ errors, valid }">
                    <v-textarea outlined :rows="3" maxlength="1000" auto-grow counter v-model="frame_update.reason" :label="$t('time_management.frame_update_reason')" :error-messages="errors"></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>  
              <v-row v-if="frame_update.action === 'delete'">
                <v-col cols="12" class="pt-0">
                  <v-checkbox color="error" class="ma-0" v-model="frame_update.delete_checked" :label="$t('time_management.delete_check')" hide-details @change="deleteChecked($event)"></v-checkbox>
                </v-col>
              </v-row>              
            </v-container>
          </v-card-text>
          <v-card-actions class="pr-10 mb-10">
            <v-spacer></v-spacer>
            <v-btn color="secondary" depressed :disabled="invalid || frame_update.deleting" :loading="frame_update.saving" @click="saveFrame" v-if="frame_update.action !== 'delete'">{{$t('generic.actions.save')}}</v-btn>
            <v-btn color="error" depressed :disabled="invalid || !frame_update.delete_checked || frame_update.saving" :loading="frame_update.deleting" @click="deleteFrame" v-else>{{$t('generic.actions.delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="frame_logs.dialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="500px" v-if="frame_logs.frames.length">
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-card class="pb-4" :min-height="$vuetify.breakpoint.xsOnly?'100vh':'auto'">
          <v-btn icon style="position:absolute;right:0;" @click="frame_logs.dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-title class="py-6">
            {{$t('time_management.logs.title')}}
          </v-card-title>
          <v-card-text>
            <v-container v-for="frame in frame_logs.frames" :key="frame.id">
              <v-row>
                <v-col cols="12">
                  <b>{{timeToLocal(frame.start_time,true)}} - {{timeToLocal(frame.end_time,true)}}</b>
                </v-col>
              </v-row>
              <v-row v-for="(log,index) in frame.logs" :key="index">
                <v-col cols="12">
                  <div v-if="log.from_start_time">
                    <span v-html="$t('time_management.logs.altered',{name:log.name,date:timeToLocal(log.created_at,true)})"></span>:
                    <ul>
                      <li v-html="$t('time_management.logs.altered_start',{from:timeToLocal(log.from_start_time,true),to:timeToLocal(log.start_time,true),date:timeToLocal(log.created_at,true)})" v-if="log.from_start_time !== log.start_time"></li>
                      <li v-html="$t('time_management.logs.altered_end',{from:timeToLocal(log.from_end_time,true),to:timeToLocal(log.end_time,true),date:timeToLocal(log.created_at,true)})" v-if="log.from_end_time !== log.end_time"></li>
                    </ul>
                  </div>
                  <div v-else-if="log.start_time">
                    <span v-html="$t('time_management.logs.new',{name:log.name,date:timeToLocal(log.created_at,true)})"></span>:
                    <ul>
                      <li v-html="$t('time_management.logs.new_start',{time:timeToLocal(log.start_time,true),date:timeToLocal(log.created_at,true)})"></li>
                      <li v-html="$t('time_management.logs.new_end',{time:timeToLocal(log.end_time,true),date:timeToLocal(log.created_at,true)})"></li>
                    </ul>
                  </div>
                  <div v-html="$t('time_management.logs.approved',{name:log.name,date:timeToLocal(log.created_at,true)})" v-else-if="log.approved"></div>
                  <div v-html="$t('time_management.logs.deleted',{name:log.name,from:timeToLocal(frame.start_time,true),to:timeToLocal(frame.end_time,true),date:timeToLocal(log.created_at,true)})" v-else-if="log.deleted"></div>
                </v-col>
              </v-row>            
            </v-container>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <v-snackbar v-model="frame_update.snackbar.invalid_timeframe" :timeout="10000" top color="error">
      <h6 class="ma-auto text-center" style="width:100%">{{ $t('time_management.snackbar.invalid_timeframe') }}</h6>
      <span v-if="frame_update.snackbar.invalid_timeframe_obj">
        {{timeToLocal(frame_update.snackbar.invalid_timeframe_obj.start_time)+' - '+timeToLocal(frame_update.snackbar.invalid_timeframe_obj.end_time)}}
        <span v-if="frame_update.snackbar.invalid_timeframe_obj.start_time > frame_update.snackbar.invalid_timeframe_obj.end_time">
          (+1)
        </span>
      </span>
    </v-snackbar>

  </div>
</template>
<script>
  import * as moment from 'moment';
  import 'moment-duration-format';
  import axios from 'axios';
  export default {
    name: 'TimeManagementComponent',
    data: () => ({
      tab: 1,
      moment:moment,
      today: new Date().toISOString().substr(0,10),
      date: null,
      directionsService: null,
      directionsDisplay: null,
      frame_logs:{
        dialog: false,
        frames: []
      },
      frame_update: {
        action: null,
        date: null,
        frame_index: null,
        dialog: false,
        delete_checked: false,
        reason: null,
        saving: false,
        deleting: false,
        start_time:{
          menu: null,
          val: null,
        },
        end_time:{
          menu: null,
          val: null,
        },
        snackbar:{
          invalid_timeframe: false,
          invalid_timeframe_obj: null
        }
      }
    }),
    created(){
      /*vm.$store.dispatch('updateUserData',{is_refresh:true}).then(()=>{
        vm.init();
      });*/
      this.init();
    },
    mounted(){

    },
    watch: {
      date(){
        if(this.date)
          this.loadMap();
      },
      tab(){
        let vm =this;
        if(this.tab===2){
          this.$nextTick().then(function () {
            vm.loadMap();
          });
        }
      },
      loadingTimeManagementData(){
        this.init();
      }
    },
    computed:{
      working_frames_on_date(){
        if(this.date)
          return this.$store.state.user.working_frames[this.date];
        else
          return null;
      },
      isEndTimeNextDay(){
        if(!this.frame_update.start_time.val || !this.frame_update.end_time.val)
          return false;
        return this.frame_update.start_time.val > this.frame_update.end_time.val;
      },
      loadingTimeManagementData(){
        return this.$store.state.vars.loading.time_management;
      },
      allowed_dates(){
        var allowed = this.$store.state.user.working_dates;
        if(!allowed.includes(this.today))
          allowed.push(this.today);
        return allowed;
      }
    },
    methods:{
      init(){
        if(this.loadingTimeManagementData)
          return false;  
        //if(this.allowed_dates){
        //this.date = this.allowed_dates[this.allowed_dates.length - 1];
        //}
        this.date = moment().format('YYYY-MM-DD');
      },
      initMap(){
        let vm = this;
        if (window.google && window.google.maps)
          return true;
        window.mapApiInitialized = ()=>{vm.loadMap()};
        let script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBnH6jj2U8vg-nobcz9UPaL3XRRKKN_Ku4&libraries=places,geometry&callback=mapApiInitialized';
        document.body.appendChild(script);
      },
      scrollTo(id){
        this.$nextTick(() => {
          setTimeout(function() {document.getElementById(id).scrollIntoView({ behavior: 'smooth'});}, 100);
        })
      },
      timeToLocal(date,with_date=false){
        if(date){
          var m = moment.utc(date).local();
          if(with_date)
            return m.locale(this.$app_lang).format('D MMM HH:mm');
          else
            return m.format('HH:mm');
        }else{
          return this.$t('time_management.working').toLowerCase();
        }
      },
      duration(time,only_hours=false,timeframe='seconds'){
        var duration = moment.duration(time,timeframe);
        if(only_hours)
          return duration.format("h[h]", { trim: false })
        else
          return duration.format("h[h] mm[m]", { trim: false })
      },
      allowedDates(val){
        return this.allowed_dates.indexOf(val) !== -1;
      },
      restingOnDate(date){
        var frames = this.$store.state.user.working_frames[date];
        if(!frames || !frames.length)
          return 0;
        var rested = 0;
        var start_time = null;
        var end_time = null;
        for (var i = frames.length - 1; i >= 0; i--) {
          if(frames[i].end_time)
            end_time = moment(frames[i].end_time);
          else
            end_time = null;
          if(end_time && start_time)
            rested += moment.duration(start_time.diff(end_time)).asSeconds();
          start_time = moment(frames[i].start_time);
        }
        return rested;
      },
      displayFrameDialog(action,frame_index){
        var frame = frame_index !== null && frame_index > -1 ? this.working_frames_on_date[frame_index] : null;
        this.frame_update.action = action;
        this.frame_update.reason = null;
        this.frame_update.delete_checked = false;
        this.frame_update.start_time.val = frame ? this.timeToLocal(frame.start_time) : null;
        this.frame_update.end_time.val = frame ? this.timeToLocal(frame.end_time) : null;
        this.frame_update.date = this.date;
        this.frame_update.frame_index = frame_index;
        this.frame_update.dialog = true;
      },
      deleteChecked(e){
        if(e && !this.frame_update.reason)
          this.frame_update.reason = this.$t('time_management.default_delete_reason');
      },
      saveFrame(){
        let vm = this;
        vm.frame_update.saving = true;
        vm.frame_update.snackbar.invalid_timeframe_obj = null;
        var start_time = moment(vm.frame_update.date+' '+vm.frame_update.start_time.val+':00').utc();
        var a = start_time.format('YYYY-MM-DD HH:mm:ss');
        var end_time = moment(vm.frame_update.date+' '+vm.frame_update.end_time.val+':00').utc();
        if(start_time.isAfter(end_time))
          end_time = end_time.add(1, 'days');
        axios({
          method: 'POST',
          url: '/api/app/employee/working_frame',
          data:{
            reason: vm.frame_update.reason,
            start_time: start_time.format('YYYY-MM-DD HH:mm:ss'),
            end_time: end_time.format('YYYY-MM-DD HH:mm:ss'),
            frame_id: vm.frame_update.frame_index !== null ? vm.$store.state.user.working_frames[vm.frame_update.date][vm.frame_update.frame_index].id : null,
            employee_id: vm.$store.state.user.id,
            company_id: vm.$store.state.user.company_id
          },
        }).then(function (response) {
          if(response.data.success){
            vm.$store.commit('updateWorkingFrame',{date:vm.frame_update.date,frame_index:vm.frame_update.frame_index,frame:response.data.frame,diff_seconds:response.data.diff_seconds});            
            vm.frame_update.dialog = false;
          }else if(response.data.why_code==='invalid_time'){
            vm.frame_update.snackbar.invalid_timeframe = true;
            vm.frame_update.snackbar.invalid_timeframe_obj = response.data.other_frame;
          }
        }, function (error) {                
        }).then(function (){  
          vm.frame_update.saving = false;
        });
      },
      deleteFrame(){
        let vm = this;
        vm.frame_update.deleting = true;
        axios({
          method: 'DELETE',
          url: '/api/app/employee/working_frame',
          data:{
            reason: vm.frame_update.reason,
            frame_id: vm.$store.state.user.working_frames[vm.frame_update.date][vm.frame_update.frame_index].id,
            company_id: vm.$store.state.user.company_id
          },
        }).then(function (response) {
          if(response.data.success){
            vm.$store.commit('deleteWorkingFrame',{frame:response.data.frame,date:vm.frame_update.date,frame_index:vm.frame_update.frame_index,diff_seconds:response.data.diff_seconds});
            vm.frame_update.dialog = false;
          }
        }, function (error) {                
        }).then(function (){  
          vm.frame_update.deleting = false;
        });
      },
      loadMap(){
        if(typeof window.google !== 'object' || !document.getElementById("map") || this.tab !== 2){
          this.initMap();
          return false;
        }
        console.log('loading map');
        let vm = this;
        var i = 0;
        var coords = vm.$store.state.user.working_coordinates[vm.date];
        if(!coords || !coords.length)
          return false;
        var MY_MAPTYPE_ID = 'custom_style';
        vm.directionsService = new window.google.maps.DirectionsService();
        vm.directionsDisplay = new window.google.maps.DirectionsRenderer({suppressMarkers:true});
        var map;
        map = new window.google.maps.Map(document.getElementById('map'), {
          mapTypeId:window.google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
          disableDefaultUI: true,
        });
        vm.directionsDisplay.setMap(map);

        var infowindow = new window.google.maps.InfoWindow();
        var flightPlanCoordinates = [];
        var bounds = new window.google.maps.LatLngBounds();

        for (i = 0; i < coords.length; i++) {
          var position = new window.google.maps.LatLng(coords[i].coord_lat, coords[i].coord_long);
          flightPlanCoordinates.push(position);          
          bounds.extend(position);
          if(i===0 || i===coords.length-1){
            var marker = new window.google.maps.Marker({
              position: position,
              map: map,
              icon: 'http://maps.google.com/mapfiles/ms/micons/'+(i?'red':'green')+'-dot.png'
              //icon: require('@/assets/logos/'+(i?'marker.png':'marker_white.png'))
            });
            window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
              return function () {
                infowindow.setContent(vm.$t('time_management.map.'+(i?'end':'start')));
                infowindow.open(map, marker);
              }
            })(marker, i));
          }
        }

        map.fitBounds(bounds);

        const flightPath = new window.google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: vm.$vuetify.theme.themes.light.secondary,
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
        flightPath.setMap(map);
      },
    }  
  }
</script>

<style type="text/css">
#TimeManagementComponent table{
  width: 100%;
}
#TimeManagementComponent table th{
  text-transform: capitalize;
}
#TimeManagementComponent .gmap{
  width: 100%;
  height: 70vh;
}
#TimeManagementComponent .v-slide-group__prev--disabled {
  display: none !important;
}
#TimeManagementComponent .v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tab:first-child, .v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab{
  margin-left: 0!important;
}
#TimeManagementComponent .v-application--is-ltr .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-bar__content > *:last-child, .v-application--is-ltr .v-tabs--centered > .v-tabs-bar .v-tabs-bar__content > *:last-child{
  margin-right: 0!important
}
</style>