<template>
  <div style="height:100%">
    <v-card id="DrawerComponent" min-height="400" style="border-radius:unset; height:100%;">
      <!--v-btn :color="$store.state.vars.loading.user?'primary':'white'" icon style="z-index:5;position:fixed;top:10px;left:50%;margin-left:-18px" :loading="$store.state.vars.loading.user" @click="$store.dispatch('updateUserData',{is_refresh:true})"><v-icon>mdi-refresh</v-icon></v-btn-->
      <v-btn color="primary" class="hidden-sm-and-up" style="z-index:5;position:fixed;top:10px;right:10px" @click="drawer.model=!drawer.model"><v-icon>mdi-menu</v-icon></v-btn>
      <v-navigation-drawer v-model="drawer.model" color="primary" :expand-on-hover="!$vuetify.breakpoint.xsOnly&&!$vuetify.breakpoint.xlOnly" :mini-variant="!$vuetify.breakpoint.xsOnly&&!$vuetify.breakpoint.xlOnly" :permanent="!$vuetify.breakpoint.xsOnly" absolute dark :temporary="$vuetify.breakpoint.xsOnly" class="pt-12" v-bind:style="{position:'fixed','z-index':3,'padding-bottom': $vuetify.breakpoint.xsOnly ? 0 : '80px'}">
        <v-list-item-group v-model="drawer.item" color="primary" class="pt-2 pb-5">
          <v-list dense nav>
            <v-list-item value="profile" two-line class="px-0" @click="$router.push({name: 'profile'})">
              <v-list-item-avatar class="mr-3">
                <img :src="$store.state.user.picture.url_t+($store.state.user.picture.code?('&tk='+$store.state.vars.has_bearer):'')">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{$store.state.user.name}}</v-list-item-title>
                <v-list-item-subtitle>{{$store.state.user.email}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <div class="pl-2 hidden-sm-and-up" v-if="$store.state.user.company&&$store.state.user.company.logo">
              <div class="mx-auto py-1" style="background:white;width:130px;border-radius:10px;">
                <v-img alt="Company logo" class="shrink mx-auto" contain :src="$store.state.user.company.logo.url_m+'&tk='+$store.state.vars.has_bearer" transition="scale-transition" width="100"/>
              </div>
            </div>

            <v-divider v-if="items_to_display.length"></v-divider>

            <div v-for="item in items_to_display" :key="item.title">
              <v-divider v-if="item.divider"></v-divider>
              <v-list-item :value="item.to" :disabled="$store.state.vars.loading[item.to] || $route.name===item.to" @click="$router.push({name: item.to})">
                <v-list-item-icon class="mr-2">
                  <v-progress-circular :size="20" :width="2" color="white" indeterminate v-if="$store.state.vars.loading[item.to]"></v-progress-circular>
                  <v-icon v-else>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-divider v-if="items_to_display.length"></v-divider>

            <v-list-item value="logout" style="background-color:#cd0707;" @click="$store.dispatch('logout')">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('generic.logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-list-item-group>
      </v-navigation-drawer>
      <v-container class="pt-0 pt-sm-8 px-0 px-sm-3 pl-sm-12" style="overflow-x:hidden;height:100%">
        <v-row align="center" v-if="loading_drawer">
          <v-col cols="12" sm="10" md="6" class="pa-12 text-center" v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
            <v-skeleton-loader type="card" style="height:500px"></v-skeleton-loader>
            <v-divider inset class="my-0"></v-divider>
          </v-col>
        </v-row>
        <!--pull-to :top-load-method="refresh" :top-config="pull_to_config" :is-bottom-bounce="false" :is-top-bounce="on_top"-->
        <router-view class="ma-auto" style="max-width:1200px" v-else></router-view>
        <!--/pull-to-->
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PullTo from 'vue-pull-to'
export default {
  components: {
    PullTo
  },
  data () {
    return {
      on_top: true,
      loading_drawer: true,
      drawer: {
        model: true,
        item: null,
      },
      pull_to_config:{
        pullText: '↻',
        triggerText: '⟳',
        loadingText: '...', 
        doneText: '',
        failText: '', 
      }
    }
  },
  created(){
    if(this.$vuetify.breakpoint.xsOnly)
      this.drawer.model = false;
  },
  mounted(){
    let vm = this;
    setTimeout(function() {vm.updateDrawerItem()}, 1000);
    window.addEventListener("scroll", ()=>{vm.on_top=window.pageYOffset === 0});
  },
  watch: { 
    /*'$route.name': {
      handler: function() {
        this.updateDrawerItem();
      },
      deep: true,
      immediate: true
    }*/
    items_to_display(){
      this.updateDrawerItem();
    }
  },
  computed:{
    items_to_display(){
      var arr = [
        //{ title: this.$root.$t('drawer.time_control'), icon: 'mdi-timer-outline', to:'time_control' },
        ]
        var user = this.$store.state.user;
        if(user.company_id){
          if(user.company.working_timeframes_enabled)
            arr.push({ title: this.$root.$t('drawer.time_control'), icon: 'mdi-timer-outline', to:'time_control' });
          if(user.working_frames && Object.keys(user.working_frames).length)
            arr.push({ title: this.$t('drawer.time_management'), icon: 'mdi-calendar-clock', to:'time_management'});
          if(user.assessments && user.assessments.length)
            arr.push({ title: this.$t('drawer.assessments'), icon: 'mdi-format-list-checks', to:'assessments'});          
          if(user.payslips && user.payslips.length)
            arr.push({ title: this.$t('drawer.payslips'), icon: 'mdi-receipt', to:'payslips'});
          if(user.documents && user.documents.length)
            arr.push({ title: this.$t('drawer.documents'), icon: 'mdi-text-box-multiple', to:'documents'});
          if(user.courses && Object.keys(user.courses).length)
            arr.push({ title: this.$t('drawer.courses'), icon: 'mdi-school', to:'courses'});
        }
        return arr;
      }
    },
    methods:{
      updateDrawerItem(){
        if(this.$route.name === 'welcome' || this.$route.name === 'profile'){
          if(this.items_to_display.length){
            this.$router.push({name: this.items_to_display[0].to});
            this.drawer.item = this.items_to_display[0].to;
          }else{
            this.$router.push({name: 'profile'});
            this.drawer.item = 'profile';
          }
        }else{
          this.drawer.item = this.$route.name;
        }
        this.loading_drawer = false;
      },
      refresh(loaded) {
        this.$store.dispatch('updateUserData',{is_refresh:true}).then(()=>{
          loaded('done')
        });
      }
    }
  };
  </script>

  <style type="text/css">
  .vue-pull-to-wrapper > .scroll-container{
    overflow-y: auto !important;
  }
/*.vue-pull-to-wrapper > .action-block.action-block-top{
  height: auto!important;
  margin-top: auto!important;
}*/
</style>