<template>
  <div id="AssessmentsComponent" style="height:100%">
    <div style="height:100%">
      <v-row justify="center" align="center" v-if="$store.state.vars.loading.assessments">
        <v-col cols="12" class="text-center">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" style="height:100%" v-else-if="!assessments.length">
        <v-col cols="12" class="text-center">
          <div class="text-subtitle-1">{{$t('assessment.no_data')}}</div>
          <br>
          <v-icon x-large>mdi-file-document-outline</v-icon>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" v-else>
        <v-col cols="12" class="pl-12 text-h6 font-weight-light">{{$t('assessment.title')}}</v-col>
        <v-col cols="12">
          <v-alert text type="warning">
              {{$t('assessment.not_share_msg')}}
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-data-table fixed-header :headers="headers" :items="assessments" :items-per-page="-1" style="width:100%">
            <template v-slot:item.created_at="{ item }">
              {{timeToLocal(item.created_at,true)}}
            </template>
            <template v-slot:item.type_code="{ item }">
              {{$t('assessment.type_code.'+item.type_code)}}
            </template>
            <template v-slot:item.percentage_answered="{ item }">
              <v-progress-circular :rotate="-90" size="35" :width="2" :value="item.percentage_answered" :color="item.percentage_answered<34?'error':(item.percentage_answered<67?'warning':'success')">
                <span class="text-caption" v-if="item.percentage_answered<100">
                  {{ item.percentage_answered }}%
                </span>
                <v-icon small color="success" v-else>mdi-check</v-icon>
              </v-progress-circular>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="text-align:right;">
                <v-btn small icon color="secondary" :href="item.url" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import * as moment from 'moment';
import axios from 'axios';
export default {
  name: 'AssessmentsComponent',
  data: () => ({
    headers:[],
  }),
  created(){
    this.init();
  },
  mounted(){
  },
  watch: { 
  },
  computed:{
    assessments(){
      return this.$store.state.user.assessments;
    }
  },
  methods:{
    init(){
      this.headers = [
        {value: 'name', text: this.$t('generic.name')},
        {value: 'percentage_answered', text:'%'},
        {value: 'type_code', text: this.$t('assessment.type')},
        {value: 'created_at', text: this.$t('assessment.created_on')},        
        {value: 'actions', text: ' ', sortable: false },
        ];
    },
    timeToLocal(date,with_date=false){
      if(date){
        var m = moment.utc(date).local();
        if(with_date)
          return m.locale(this.$app_lang).format('HH:mm | D MMM YYYY');
        else
          return m.format('HH:mm');
      }else{
        return this.$t('time_management.working').toLowerCase();
      }
    },
  }  
}
</script>